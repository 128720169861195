import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import ModalComponent from "../../components/layout/shared/ModalComponent";
import ModalWrapper from "../../components/layout/shared/ModalWrapper";
import SuperAdminAddUpn from "./SuperAdminAddUpn";
import SuperAdminEditUpn from "./SuperAdminEditUpn";
import { GoPasskeyFill } from "react-icons/go";
import PaginationEllipsis from "../../components/layout/shared/PaginationEllipsis";
import { getCompanyList } from "../../redux/slice/companySlice";
import { getUpnNamesByCompanyId } from "../../redux/slice/upnSlice";
import Select from "react-select";
import { Control, colourStylesState } from "../../configs/stylesConfig";
import Spinner from "../../components/layout/shared/Spinner";
import InputField from "../../components/layout/shared/InputField";
import { DELETEUPNDATA } from "../../services/upnService.js";
import { toast } from "react-toastify";

const PowerBiUpnList = (props) => {
  const listRef = useRef();
  const upnListFromReducer = useSelector(
    ({ upn }) => upn.userPrincipalNameList
  );
  const upnLoading = useSelector(({ upn }) => upn.upnLoading);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const companyListFromReducer = useSelector(
    ({ company }) => company.companyList
  );

  listRef.current = upnListFromReducer?.length && upnListFromReducer;

  const tableHead = {
    name: "Roles",
    action: "Actions",
  };

  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const maxVisiblePages = 5;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showEditUpnModal, setShowEditUpnModal] = useState(false);
  const [upnId, setUpnId] = useState("");

  const [showAddUpnModal, setShowAddUpnModal] = useState(false);
  const reportLoading = useSelector(({ report }) => report.loading);

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(upnListFromReducer.length / countPerPage);
    const items = [];

    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <li
          key={i}
          className={
            i === currentPage
              ? "rc-pagination-item rc-pagination-item-active"
              : "rc-pagination-item"
          }
          onClick={() => updatePage(i)}
        >
          {i}
        </li>
      );
    }

    if (totalPages > maxVisiblePages) {
      if (currentPage > maxVisiblePages - 2) {
        items.splice(1, 0, <PaginationEllipsis key="ellipsis" />);
      }
      if (currentPage < totalPages - 2) {
        items.splice(-1, 0, <PaginationEllipsis key="ellipsis" />);
      }
    }

    return items;
  };

  const handleAddUpnModalShow = () => {
    setShowAddUpnModal(true);
  };

  const handleAddUpnModalClose = () => {
    setShowAddUpnModal(false);
  };

  const handleEditUpnModalShow = (upnId) => {
    setUpnId(upnId);
    setShowEditUpnModal(true);
  };

  const handleEditUpnModalClose = (upnId) => {
    setShowEditUpnModal(false);
  };

  const deleteUpnHandler = async () => {
    setLoading(true);
    let success = false;

    try {
      const deletedResponse = await DELETEUPNDATA(upnId);

      if (deletedResponse?.data?.acknowledged) {
        success = true;
        dispatch(getUpnNamesByCompanyId(selectedCompany?.id));
      }
    } catch (error) {
      // Handle the error here, for example, log the error message or show a notification to the user.
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);

      if (success) {
        toast.success("UPN has been removed successfully");
      }
    }
  };

  const handleDeleteModalShow = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = (stId) => {
    setShowDeleteModal(false);
  };

  const rowDeleteHandler = (upn_Id) => {
    setUpnId(upn_Id);
    handleDeleteModalShow(upnId);
  };

  const rowEditHandler = (upnId) => {
    handleEditUpnModalShow(upnId);
  };

  const [collection, setCollection] = useState();

  useEffect(() => {
    if (upnListFromReducer?.length) {
      setCollection(upnListFromReducer);
    } else if (upnListFromReducer?.length === 0) {
      setCollection([]);
    }
  }, [upnListFromReducer]);

  const searchData = useRef(
    throttle((val) => {
      const query = val?.trim().toLowerCase();
      setCurrentPage(1);
      const data =
        listRef?.current?.length &&
        listRef?.current
          ?.filter((item) => {
            return item?.user_principal_name.toLowerCase().indexOf(query) > -1;
          })
          ?.slice(0, countPerPage);

      setCollection(data);
    }, 300)
  );

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(upnListFromReducer?.slice(from, to));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <th key={index}>{title}</th>
    ));
  };

  useEffect(() => {
    setCompanyLoading(true);
    dispatch(getCompanyList())
      .then((res) => {
        setCompanyLoading(false);
      })
      .catch((err) => {
        setCompanyLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (companyListFromReducer?.length) {
      setCompanyOptions(companyListFromReducer);
    }
  }, [dispatch, companyListFromReducer]);

  useEffect(() => {
    if (
      selectedCompany?.id !== undefined &&
      selectedCompany?.id !== "" &&
      companyListFromReducer?.length
    ) {
      setLoading(true);

      dispatch(getUpnNamesByCompanyId(selectedCompany?.id))
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [selectedCompany]);

  return (
    <Container fluid className="p-0">
      <Row className="searchContainer">
        <Col lg={5} md={5}>
          <div className="">
            <Select
              className=""
              options={companyOptions}
              onChange={(e) => {
                setSelectedCompany(e);
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={" "}
              disabled={companyLoading}
              value={selectedCompany}
              styles={colourStylesState}
              isClearable={false}
              label={companyLoading ? "Loading options" : "Company"}
              components={{
                Control: Control,
                // DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </Col>
        <Col lg={5} md={5} className="search">
          <InputField
            className={"did-floating-input"}
            classNameLabel={"did-floating-label"}
            type="text"
            name="lastName"
            label={"UPN"}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Col>

        <Col
          lg="2"
          md={2}
          className="new-message-btn d-flex gap-2 justify-content-end"
        >
          <Tooltip
            title={selectedCompany?.id ? "Add UPN" : "Select Company first"}
          >
            <Button
              className="new-message"
              onClick={selectedCompany?.id && handleAddUpnModalShow}
            >
              <GoPasskeyFill />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <div className="table-responsive">
        <table id="paginationTable">
          <thead id="paginationTableHead">
            <tr id="paginationTableRow">{headRow()}</tr>
          </thead>
          <tbody id="paginationTableBody" className="trhover">
            {collection?.length === 0 ? (
              <tr>
                <td>
                  {selectedCompany
                    ? "No Records Found"
                    : "Please Select Company"}
                </td>
              </tr>
            ) : (
              collection?.length &&
              collection?.map((upn, index) => {
                return (
                  <tr key={upn?._id}>
                    <td>{upn?.user_principal_name}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "24px",
                          padding: "4px",
                        }}
                      >
                        <Tooltip title="Remove UPN">
                          <Link
                            onClick={() => {
                              rowDeleteHandler(upn?._id);
                            }}
                          >
                            <i
                              style={{ color: "#3a49a1" }}
                              className="fa fa-times"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </Tooltip>
                        <Tooltip
                          title={
                            selectedCompany?.id
                              ? "Edit UPN"
                              : "Select Company first"
                          }
                        >
                          <Link
                            disabled={selectedCompany?.id ? false : true}
                            onClick={() => {
                              rowEditHandler(upn?._id);
                            }}
                          >
                            <img
                              src="/assets/images/editIcon.svg"
                              className="img-fluid"
                              alt="icon"
                            />
                          </Link>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        showTitle={false}
        className="paginate-sec"
        pageSize={countPerPage}
        onChange={updatePage}
        current={currentPage}
        total={upnListFromReducer.length}
        showLessItems={true}
      >
        {renderPaginationItems()}
      </Pagination>

      <ModalComponent
        show={showDeleteModal}
        handleClose={handleDeleteModalClose}
        keyboard={true}
        size="md"
      >
        <div className="delete-modal">
          <i className="fa fa-trash" aria-hidden="true"></i>
          <h4>Remove</h4>
          <p>Are you sure you want to Remove this UPN ?</p>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <Button className="white-back" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button className="red-back" onClick={deleteUpnHandler}>
              Remove
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalWrapper
        showModal={showAddUpnModal}
        handleClose={handleAddUpnModalClose}
        title="Add UPN"
        keyboard={true}
        size="lg"
      >
        <SuperAdminAddUpn
          handleAddUpnModalClose={handleAddUpnModalClose}
          company={selectedCompany}
        />
      </ModalWrapper>
      <ModalWrapper
        keyboard={true}
        showModal={showEditUpnModal}
        handleClose={handleEditUpnModalClose}
        title={"Update UPN"}
        size="lg"
      >
        <SuperAdminEditUpn
          company={selectedCompany}
          upnId={upnId}
          handleEditUpnModalClose={handleEditUpnModalClose}
        />
      </ModalWrapper>

      <Spinner
        isLoading={loading || upnLoading || companyLoading || reportLoading}
      />
    </Container>
  );
};
export default PowerBiUpnList;
