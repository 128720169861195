/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GETALLREPORTS,
  GETREPORTSBYGROUPID,
  GetReportById,
} from "../../services/reportService";

export const getReportByGroupId = createAsyncThunk(
  "report/getReportByGroupId",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETREPORTSBYGROUPID(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//For Superadmin
export const getAllReports = createAsyncThunk(
  "report/getAllReports",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETALLREPORTS(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getReportByReportId = createAsyncThunk(
  "email/getReportByReportId",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GetReportById(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  reportList: [],
  loading: false,
  reportAccessToken: "",
  report: null,
};

const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  
  extraReducers: (builder) => {
    builder.addCase(getAllReports.pending, (state, action) => {
      state.loading = true;
      state.reportList = [];
    });
    builder.addCase(getAllReports.fulfilled, (state, action) => {
      state.reportList = action.payload?.data?.value;
      state.reportAccessToken = action.payload?.accessToken;
      state.loading = false;
    });
    builder.addCase(getAllReports.rejected, (state, action) => {
      state.loading = false;
      state.reportList = [];
    });
    builder.addCase(getReportByGroupId.pending, (state, action) => {
      state.loading = true;
      state.reportList = [];
    });
    builder.addCase(getReportByGroupId.fulfilled, (state, action) => {
      state.reportList = action.payload?.data;
      state.reportAccessToken = action.payload.accessToken;
      state.loading = false;
    });
    builder.addCase(getReportByGroupId.rejected, (state, action) => {
      state.loading = false;
      state.reportList = [];
    });
    builder.addCase(getReportByReportId.pending, (state, action) => {
      state.loading = action.payload?.true;
      state.report = [];
    });
    builder.addCase(getReportByReportId.fulfilled, (state, action) => {
      state.report = action.payload?.data;
      state.loading = false;
    });
  },
});

export const {  setSelectedCompanyData } = reportSlice.actions;

export default reportSlice.reducer;
