import React, { useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toggleLoading } from "../../redux/slice/loadingSlice";
import { getReportByGroupId } from "../../redux/slice/reportSlice";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { SiPowerbi } from "react-icons/si";
import CompanyAdminSidebarMenu from "./CompanyAdminSidebarMenu";

const CompanyAdminSidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "auto",
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  const [route, setRoute] = useState([
    {
      path: "/companyadmin/dashboard",
      name: "Dashboard",
      icon: (
        <div>
          <img
            width="20px"
            height="20px"
            src={"/assets/images/dashboard-grey.svg"}
            style={{ maxHeight: "70px" }}
            alt="dashboard icon"
            className="logo-grey"
          />
          <img
            width="20px"
            height="20px"
            src={"/assets/images/dashboard-white.svg"}
            style={{ maxHeight: "70px" }}
            alt="dashboard icon"
            className="logo-white"
          />
        </div>
      ),
    },
    {
      path: "/companyadmin/user-management",
      name: "Users",
      icon: <FaUsers width="20px" height="20px" />,
    },
    {
      path: "/companyadmin/reports-list",
      name: "Reports",
      icon: <SiPowerbi />,
    },
  ]);

  const dispatch = useDispatch();
  const reportsListFromReducer = useSelector(({ report }) => report.reportList);
  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);

  useEffect(() => {
    if (loggedInUser) {
      dispatch(toggleLoading(true));
      dispatch(getReportByGroupId(loggedInUser?.company?.company_id));
      dispatch(toggleLoading(false));
    }
  }, [dispatch, loggedInUser]);

  useEffect(() => {
    if (reportsListFromReducer?.length) {
      const updatedData = reportsListFromReducer?.map((report) => {
        return {
          path: `/companyadmin/reports/${report?.id}`,
          name: report?.name,
          report: report,
        };
      });

      setRoute((old) => {
        return old.map((el) => {
          if (el?.name === "Reports") {
            return {
              ...el,
              subRoutes: updatedData,
            };
          }
          return el;
        });
      });
    }
  }, [reportsListFromReducer]);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="dashboard-main-sec">
      <div
        style={{
          width: isOpen ? "270px" : "76px",
        }}
        className="sidebar"
      >
        <div className="top_section">
          <div
            style={{ marginLeft: isOpen ? "50px" : "0px", cursor: "pointer" }}
            className="bars"
          >
            {isOpen ? (
              <BsFillArrowLeftCircleFill onClick={toggle} />
            ) : (
              <BsFillArrowRightCircleFill onClick={toggle} />
            )}
          </div>
        </div>
        <div className="set-side-height">
          {route.map((route, index) => {
            if (route?.subRoutes) {
              return (
                <CompanyAdminSidebarMenu
                  key={route.name}
                  route={route}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              );
            }
            return (
              <NavLink
                to={route.path}
                key={index}
                className="link"
                activeClassName="active"
                state={{ report: route.report }}
              >
                <div className="icon">{route.icon}</div>
                <div
                  style={{
                    display: isOpen ? "block" : "none",
                    fontFamily: "Segoe UI",
                  }}
                  className="link_text"
                >
                  {route.name}
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default CompanyAdminSidebar;
