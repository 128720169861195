import { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";

import { yupResolver } from "@hookform/resolvers/yup";

import { Container, Form, Row, Col } from "react-bootstrap";
import { CompanyAdminInviteSchema } from "../../utils/validationSchema/validation";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersByCompanyId,
  inviteUser,
} from "../../redux/slice/userSlice";
import { toast } from "react-toastify";
import { CompanyAdminRoleOptions } from "../../utils/data/staticData";
import { getUpnNamesByCompanyId } from "../../redux/slice/upnSlice";
import InputField from "../../components/layout/shared/InputField";
import Spinner from "../../components/layout/shared/Spinner";
import {
  colourStylesState,
  ControlAstrisk,
  multiColorStyles,
} from "../../configs/stylesConfig";
import { getJobTitlesByCompanyId } from "../../redux/slice/jobTitleSlice";

const defaultValues = {
  email: "",
  company: {
    name: "",
    id: "",
  },
  user_principal: [],
  role: {
    label: "",
    value: "",
  },
  job_title: {
    label: "",
    value: "",
  },
};

const CompanyAdminInviteUserForm = (props) => {
  // ** States

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isUpnDisabled, setIsUpnDisabled] = useState(true);
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [userPrincipalOptions, setUserPrincipalOptions] = useState([]);

  const jobTitleListFromReducer = useSelector(
    ({ jobTitle }) => jobTitle.jobTitlesList
  );

  const jobTitleLoading = useSelector(
    ({ jobTitle }) => jobTitle.jobTitleLoading
  );

  const {
    handleSubmit,
    watch,
    handleBlur,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(CompanyAdminInviteSchema),
  });

  const form = watch();

  const onSubmit = async (data) => {
    setLoading(true);
    const params = {
      email: data?.email && data.email.toLowerCase(),
      user_principal: [],
      job_title: data?.job_title?.value,
      company: {
        company_name: data?.company.name,
        company_id: data?.company?.id,
      },

      role: [data?.role?.value],
    };

    try {
      const inviteData = await dispatch(inviteUser(params));

      if (!inviteData?.payload || inviteData?.payload === undefined) {
        setLoading(false);
        return;
      } else {
        setLoading(false);
        dispatch(getAllUsersByCompanyId(loggedInUser?.company?.company_id));
        props?.handlePageAfterEdit();
        props?.handleInviteModalClose();
        toast.success("User has been invited successfully");
      }
    } catch (error) {
      // Handle the error here, you can show an error message or log it.
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);

  useEffect(() => {
    if (loggedInUser?.company) {
      setValue("company", {
        name: loggedInUser?.company?.company_name,
        id: loggedInUser?.company?.company_id,
      });
      dispatch(getUpnNamesByCompanyId(loggedInUser?.company?.company_id)).then(
        (response) => {
          const data = response.payload;

          const loadOptions = data?.map((el) => {
            return {
              value: el?.user_principal_name,
              label: el.user_principal_name,
            };
          });

          setUserPrincipalOptions(loadOptions);
        }
      );
      setIsUpnDisabled(false);
    } else {
      setIsUpnDisabled(true);
    }
  }, [dispatch, loggedInUser?.company]);

  useEffect(() => {
    if (
      loggedInUser?.company?.company_id !== undefined &&
      loggedInUser?.company?.company_id !== ""
    ) {
      setLoading(true);

      dispatch(getJobTitlesByCompanyId(loggedInUser?.company?.company_id))
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [loggedInUser?.company]);

  useEffect(() => {
    if (jobTitleListFromReducer) {
      const loadOptions = jobTitleListFromReducer.map((el) => {
        return {
          value: el?.job_title,
          label: el?.job_title,
        };
      });
      setJobTitleOptions(loadOptions);
    }
  }, [jobTitleListFromReducer]);

  return (
    <section className="">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="">
              <Container>
                <Row className="align-items-center">
                  <Col md="12" className="m-auto">
                    <div className="">
                      <Row className="align-items-center">
                        <Col md={12}>
                          <div className="login-form">
                            <div className="d-flex align-items-center justify-content-between"></div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                              <Row>
                                <Col lg="12">
                                  <Row>
                                    <Col md="6">
                                      <Form.Group
                                        className="did-floating-label-content mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Controller
                                          control={control}
                                          name="email"
                                          render={({ field }) => (
                                            <InputField
                                              className={"did-floating-input"}
                                              classNameLabel={
                                                "did-floating-label did-floating-labe-astrisk"
                                              }
                                              type="email"
                                              name="email"
                                              label={"Email Address"}
                                              value={field?.value}
                                              onChange={field.onChange}
                                              onBlur={handleBlur}
                                            />
                                          )}
                                        />

                                        {errors.email && (
                                          <Form.Text className="text-danger">
                                            {errors.email.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    <Col md="6">
                                      <Form.Group
                                        className="did-floating-label-content mb-3"
                                        controlId="company"
                                        style={{
                                          cursor: "not-allowed",
                                        }}
                                      >
                                        <Controller
                                          control={control}
                                          name="company"
                                          render={({ field }) => (
                                            <Select
                                              isDisabled={true}
                                              className=""
                                              onChange={(e) => {
                                                field.onChange(e);
                                                setValue("user_principal", []);
                                              }}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              getOptionValue={(option) =>
                                                option.id
                                              }
                                              placeholder={" "}
                                              disabled={true}
                                              value={field.value}
                                              styles={colourStylesState}
                                              isClearable={false}
                                              label={"Company"}
                                              components={{
                                                Control: ControlAstrisk,
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                              }}
                                            />
                                          )}
                                        />

                                        {errors?.company && (
                                          <Form.Text className="text-danger">
                                            {errors?.company?.name?.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={6}>
                                      <Form.Group
                                        className="mb-3 did-floating-label-content"
                                        controlId="job_title"
                                      >
                                        <Controller
                                          control={control}
                                          name="job_title"
                                          render={({ field }) => (
                                            <Select
                                              className=" "
                                              options={jobTitleOptions}
                                              onChange={(selectedOption) => {
                                                field.onChange(selectedOption);
                                                const upOption =
                                                  jobTitleListFromReducer?.find(
                                                    (el) =>
                                                      el?.job_title ===
                                                      selectedOption?.value
                                                  );

                                                const loadOptions =
                                                  upOption?.user_principal?.map(
                                                    (el) => ({
                                                      value: el,
                                                      label: el,
                                                    })
                                                  );

                                                setValue(
                                                  "user_principal",
                                                  loadOptions || []
                                                );
                                              }}
                                              placeholder={" "}
                                              value={field.value}
                                              styles={colourStylesState}
                                              isClearable={true}
                                              label={"Job Title"}
                                              components={{
                                                Control: ControlAstrisk,
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                              }}
                                            />
                                          )}
                                        />

                                        {errors?.job_title && (
                                          <Form.Text className="text-danger">
                                            {errors?.job_title?.label?.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                      <Form.Group
                                        className="mb-3 did-floating-label-content"
                                        controlId="role"
                                      >
                                        <Controller
                                          control={control}
                                          name="role"
                                          render={({ field }) => (
                                            <Select
                                              className=" "
                                              options={CompanyAdminRoleOptions}
                                              onChange={field.onChange}
                                              placeholder={" "}
                                              value={field.value}
                                              isClearable={true}
                                              styles={colourStylesState}
                                              label={"User Type"}
                                              components={{
                                                Control: ControlAstrisk,
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                              }}
                                            />
                                          )}
                                        />

                                        {errors.role && (
                                          <Form.Text className="text-danger">
                                            {errors?.role?.label?.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  {/* <Row>
                                    <Col md={12}>
                                      <Form.Group className="did-floating-label-content mb-3">
                                        <Controller
                                          control={control}
                                          name="user_principal"
                                          render={({ field }) => (
                                            <Select
                                              className=" "
                                              isMulti
                                              closeMenuOnSelect={false}
                                              hideSelectedOptions={false}
                                              placeholder={" "}
                                              required={true}
                                              styles={multiColorStyles}
                                              isSearchable={true}
                                              isDisabled={isUpnDisabled}
                                              options={userPrincipalOptions}
                                              components={{
                                                Control,
                                                MultiValue,
                                              }}
                                              value={field?.value}
                                              onChange={(event) => {
                                                if (!event) {
                                                  field.onChange(event);
                                                }
                                                if (
                                                  event &&
                                                  event.length > 0 &&
                                                  event.some(
                                                    (item) =>
                                                      item.value === "all"
                                                  )
                                                ) {
                                                  // If "Select All" is selected, set all options as selected
                                                  field.onChange(
                                                    userPrincipalOptions
                                                      .map((value) => value)
                                                      .filter(
                                                        (x) => x.value !== "all"
                                                      )
                                                  );
                                                } else {
                                                  field.onChange(
                                                    event.map((value) => value)
                                                  );
                                                }
                                              }}
                                              label={"Roles"}
                                            />
                                          )}
                                        />

                                        {errors.user_principal && (
                                          <Form.Text className="text-danger">
                                            {errors.user_principal.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row> */}
                                </Col>
                              </Row>
                              <div>
                                <button
                                  className={`login-btn w-100 m-0 ${
                                    loading ? "disabled" : ""
                                  }`}
                                  type="submit" // Change to type="submit" to trigger form submission
                                  disabled={loading} // Disable the button while loading
                                >
                                  Invite User
                                </button>
                              </div>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      <Spinner isLoading={loading || jobTitleLoading} />
    </section>
  );
};

export default CompanyAdminInviteUserForm;

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 100 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#24a5a5",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    color: "#fff",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};
const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 2;
  const overflow = getValue()
    ?.slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
