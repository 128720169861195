// ** React Imports
import React, { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import Select, { components } from "react-select";
import {
  colourStylesState,
  ControlAstrisk,
  multiColorStyles,
} from "../../configs/stylesConfig";

// ** Third Party Imports
import { yupResolver } from "@hookform/resolvers/yup";

import "react-phone-number-input/style.css";

import { Container, Form, Row, Col } from "react-bootstrap";
import { addJobTitleSchema } from "../../utils/validationSchema/validation";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { getUpnNamesByCompanyId } from "../../redux/slice/upnSlice";
import Spinner from "../../components/layout/shared/Spinner";
import InputField from "../../components/layout/shared/InputField";
import {
  addJobTitle,
  getJobTitlesByCompanyId,
} from "../../redux/slice/jobTitleSlice";
import { getReportByGroupId } from "../../redux/slice/reportSlice";

const defaultValues = {
  job_title: "",
  company_id: "",
  user_principal: [],
  reports: [],
};

const SuperAdminAddJobTitle = (props) => {
  const [userPrincipalOptions, setUserPrincipalOptions] = useState([]);
  const [reportOptions, setReportOptions] = useState([]);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    watch,
    register,
    handleBlur,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(addJobTitleSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const params = {
      job_title: data?.job_title,
      company_id: props?.company?.id,
      user_principal: data?.user_principal.map((item) => item.value),
      reports: data?.reports?.map((item) => item.value),
    };

    try {
      const upnData = await dispatch(addJobTitle(params));

      if (!upnData?.payload || upnData?.payload === undefined) {
        setLoading(false);
        return;
      } else {
        dispatch(getJobTitlesByCompanyId(props?.company?.id));
        setLoading(false);
        toast.success("Job Title has been created successfully");
        props?.handleAddJobTitleModalClose();
      }
    } catch (error) {
      // Handle the error here, for example, log the error or display an error message.
      console.error("An error occurred:", error);
      // Optionally, you can also set setLoading to false here if needed.
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getUpnNamesByCompanyId(props?.company?.id)).then((response) => {
      const data = response.payload;

      const loadOptions = data?.map((el) => {
        return {
          value: el?.user_principal_name,
          label: el.user_principal_name,
        };
      });

      setUserPrincipalOptions(loadOptions);
    });
  }, [dispatch, props?.company?.id]);

  useEffect(() => {
    dispatch(getReportByGroupId(props?.company?.id)).then((response) => {
      const data = response?.payload?.data;

      console.log("data", data);

      const loadOptions = data?.map((el) => {
        return {
          value: el?.name,
          label: el?.name,
        };
      });

      setReportOptions(loadOptions);
    });
  }, [dispatch, props?.company?.id]);

  return (
    <section className="">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Container>
              <Row className="align-items-center">
                <Col md="12" className="m-auto">
                  <div className="shadow-box p-0">
                    <Row className="align-items-center">
                      <Col md={12}>
                        <div className="login-form mt-0">
                          <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="name"
                                >
                                  <Controller
                                    control={control}
                                    name="name"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type="text"
                                        name="name"
                                        label={"Company Name"}
                                        value={props?.company?.name}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                        disabled={true}
                                      />
                                    )}
                                  />

                                  {errors.name && (
                                    <Form.Text className="text-danger">
                                      {errors.name.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="jobTitle"
                                >
                                  <Controller
                                    control={control}
                                    name="job_title"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type="text"
                                        name="job_title"
                                        label={"Job Title"}
                                        value={field?.value}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.job_title && (
                                    <Form.Text className="text-danger">
                                      {errors.job_title.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <Form.Group className="did-floating-label-content mb-3">
                                  <Controller
                                    control={control}
                                    name="user_principal"
                                    render={({ field }) => (
                                      <Select
                                        className=" "
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        placeholder={" "}
                                        required={true}
                                        styles={multiColorStyles}
                                        isSearchable={true}
                                        options={userPrincipalOptions}
                                        components={{ Control, MultiValue }}
                                        value={field?.value}
                                        onChange={(event) => {
                                          if (!event) {
                                            field.onChange(event);
                                          }
                                          if (
                                            event &&
                                            event.length > 0 &&
                                            event.some(
                                              (item) => item.value === "all"
                                            )
                                          ) {
                                            // If "Select All" is selected, set all options as selected
                                            field.onChange(
                                              userPrincipalOptions
                                                .map((value) => value)
                                                .filter(
                                                  (x) => x.value !== "all"
                                                )
                                            );
                                          } else {
                                            field.onChange(
                                              event.map((value) => value)
                                            );
                                          }
                                        }}
                                        label={"Roles"}
                                      />
                                    )}
                                  />

                                  {errors.user_principal && (
                                    <Form.Text className="text-danger">
                                      {errors.user_principal.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md="6">
                                <Form.Group className="did-floating-label-content mb-3">
                                  <Controller
                                    control={control}
                                    name="reports"
                                    render={({ field }) => (
                                      <Select
                                        className=" "
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        placeholder={" "}
                                        required={true}
                                        styles={multiColorStyles}
                                        isSearchable={true}
                                        options={reportOptions}
                                        components={{ Control, MultiValue }}
                                        value={field?.value}
                                        onChange={(event) => {
                                          if (!event) {
                                            field.onChange(event);
                                          }
                                          if (
                                            event &&
                                            event.length > 0 &&
                                            event.some(
                                              (item) => item.value === "all"
                                            )
                                          ) {
                                            // If "Select All" is selected, set all options as selected
                                            field.onChange(
                                              reportOptions
                                                .map((value) => value)
                                                .filter(
                                                  (x) => x.value !== "all"
                                                )
                                            );
                                          } else {
                                            field.onChange(
                                              event.map((value) => value)
                                            );
                                          }
                                        }}
                                        label={"Reports"}
                                      />
                                    )}
                                  />

                                  {errors.reports && (
                                    <Form.Text className="text-danger">
                                      {errors.reports.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Form.Group controlId="policycheck">
                                  <Form.Check
                                    type="checkbox"
                                    label="I agree to privacy policy & terms"
                                    {...register("terms", {
                                      required:
                                        "You must accept the privacy policy & terms",
                                    })}
                                  />
                                  {errors.terms && (
                                    <Form.Text className="text-danger">
                                      {errors.terms.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>

                            <div>
                              <button
                                className={`login-btn w-100 m-0 ${
                                  loading ? "disabled" : ""
                                }`}
                                type="submit"
                                disabled={loading}
                              >
                                Add Job Title
                              </button>
                            </div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Spinner isLoading={loading} />
    </section>
  );
};

export default SuperAdminAddJobTitle;

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 100 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#24a5a5",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    color: "#fff",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};
const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 2;
  const overflow = getValue()
    ?.slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
