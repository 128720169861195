import { useLayoutEffect, useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { GENERATEEMBEDTOKEN } from "../../services/reportService";

import { Box } from "@mui/material";

import * as powerbi from "powerbi-client";
import * as models from "powerbi-models";
import { useSelector } from "react-redux";
import UserLayout from "../../components/UserLayout/UserLayout";
import UserReportToolbar from "./UserReportToolbar";
import Spinner from "../../components/layout/shared/Spinner";

// Ensure Power BI JavaScript API has loaded
require("powerbi-models");
require("powerbi-client");

const UserReport = () => {
  const location = useLocation();
  const embedContainer = useRef(null);

  const [embeddedReport, setEmbeddedReport] = useState(null);

  const [embedType, setEmbedType] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showNavigation, setShowNavigation] = useState(true);
  const [showFiltersPane, setShowFiltersPane] = useState(true);
  const [showBookmarksPane, setShowBookmarksPane] = useState(false);
  const [viewMode, setViewMode] = useState("FitToPage");

  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);

  const [accessToken, setAccessToken] = useState("");
  const report = location?.state?.report;

  useEffect(() => {
    if (loggedInUser) {
      const formData = {
        reportId: report?.id,
        groupId: report?.datasetWorkspaceId,
        accessLevel: "View",
        reportName: report?.name,
        roles: loggedInUser?.user_principal,
        datasets: [`${report?.datasetId}`],
        username: loggedInUser?.email,
      };
      GENERATEEMBEDTOKEN(formData)
        .then((res) => {
          setAccessToken(res?.data?.data?.token);
        })
        .catch((err) => {});
    }
  }, [loggedInUser, report]);

  const reportLoading = useSelector(({ report }) => report.loading);

  useEffect(() => {
    if (accessToken !== "" && accessToken !== undefined) {
      embedExistingReport(report?.id);
    }
  }, [accessToken, location?.pathname, report?.id]);

  const embedExistingReport = async (ReportId) => {
    try {
      setLoading(true);
      // embedContainer.current.style.visibility = "hidden";

      const defaultShowNavigation = true;
      setShowNavigation(defaultShowNavigation);
      const defaultShowFilterPane = false;
      setShowFiltersPane(defaultShowFilterPane);
      const defaultShowBookmarksPane = false;
      setShowBookmarksPane(defaultShowBookmarksPane);
      const defaultViewMode = "FitToPage";
      setViewMode(defaultViewMode);

      setEmbedType("ExistingReport");
      setReportType("PowerBiReport");

      var config = {
        type: "report",
        id: ReportId,
        embedUrl: report?.embedUrl,
        accessToken: accessToken,
        tokenType: models.TokenType.Embed,
        viewMode: models.ViewMode.View,
        settings: {
          panes: {
            pageNavigation: {
              visible: defaultShowNavigation,
              position: models.PageNavigationPosition.Left,
            },
            filters: { visible: defaultShowFilterPane, expanded: false },
            bookmarks: { visible: defaultShowBookmarksPane },
          },
          bars: {
            actionBar: { visible: false },
          },
          persistentFiltersEnabled: true,
          personalBookmarksEnabled: true,
        },
      };

      // Embed the report and display it within the div container
      var embeddedReport = window.powerbi.embed(embedContainer.current, config);

      embeddedReport.on("rendered", (e) => {
        setLoading(false);
      });
      embeddedReport.on("error", (e) => {
        setLoading(false);
      });

      setEmbeddedReport(embeddedReport);
    } catch (error) {
      console.error("Error:", error);
      setEmbeddedReport(null);
    } finally {
      setLoading(false);
    }
  };

  // Set height of embed container relative to the height of the window
  const setReportContainerHeight = () => {
    if (embedContainer?.current) {
      var reportContainer = embedContainer.current;

      var reportContainerTopPosition =
        reportType === "PaginatedReport" ? 88 : 122;
      reportContainer.style.height =
        window?.innerHeight - reportContainerTopPosition - 10 + "px";
    }
  };

  useLayoutEffect(() => {
    if (report?.id) {
      setReportContainerHeight();
      window.addEventListener("resize", setReportContainerHeight);
    }
  }, [report?.id, reportType]);

  return (
    <UserLayout>
      <Box sx={{ display: "grid", gridAutoFlow: "row", width: 1 }}>
        {embedType === "ExistingReport" &&
          reportType === "PowerBiReport" &&
          embeddedReport !== null && (
            <UserReportToolbar
              report={embeddedReport}
              showNavigation={showNavigation}
              setShowNavigation={setShowNavigation}
              showFiltersPane={showFiltersPane}
              setShowFiltersPane={setShowFiltersPane}
              viewMode={viewMode}
              setViewMode={setViewMode}
              showBookmarksPane={showBookmarksPane}
              setShowBookmarksPane={setShowBookmarksPane}
            />
          )}

        <Box ref={embedContainer} sx={{ width: "100%" }} />
      </Box>
      <Spinner isLoading={loading || reportLoading} />
    </UserLayout>
  );
};

export default UserReport;
