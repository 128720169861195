import React, { useState, useEffect, useRef, useCallback } from "react";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/layout/shared/Spinner";
import ModalComponent from "../../components/layout/shared/ModalComponent";
import ModalWrapper from "../../components/layout/shared/ModalWrapper";
import { Button, Container } from "react-bootstrap";
import SuperAdminAddCompany from "./SuperAdminAddCompany";
import SuperAdminEditCompany from "./SuperAdminEditCompany";
import InputField from "../../components/layout/shared/InputField";
import { getCompanyList } from "../../redux/slice/companySlice";

const CompanyListForSuperAdmin = () => {
  const [loading, setLoading] = useState(false);
  const reportLoading = useSelector(({ report }) => report.loading);
  const companyLoading = useSelector(({ company }) => company.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getCompanyList())
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);

  const tableHead = {
    name: "Name",
    companyType: "Company Type",
    defaultDatasetStorageFormat: "Default Dataset Storage Format",
    companyId: "Company Id",
  };

  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [companyId, setCompanyId] = useState("");

  const companyListFromReducer = useSelector(
    ({ company }) => company.companyList
  );
  const companyListRef = useRef();
  companyListRef.current =
    companyListFromReducer?.length && companyListFromReducer;

  const handleEditWorkspaceModalShow = (companyId) => {
    setCompanyId(companyId);
    setShowEditUserModal(true);
  };

  const handleEditWorkspaceModalClose = (companyId) => {
    setShowEditUserModal(false);
  };

  const deleteWorkspaceHandler = () => {
    setShowDeleteModal(false);
    setShowDeleteSuccessModal(true);
  };

  const handleDeleteModalShow = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = (stId) => {
    // setStudentId(stId);
    setShowDeleteModal(false);
  };

  const handleDeleteSuccessModalClose = () => {
    setShowDeleteSuccessModal(false);
  };

  const rowDeleteHandler = (row) => {
    handleDeleteModalShow(row?.stId);
  };

  const rowEditHandler = (companyId) => {
    handleEditWorkspaceModalShow(companyId);
  };
  const rowClickHandler = (row) => {
    // navigate(`/staff/mail/inbox/${row?.id}`, <MailDetail />);
  };

  const [showAddWorkspaceModal, setShowWorkspaceModal] = useState(false);

  const handleAddWorkspaceModalShow = () => {
    setShowWorkspaceModal(true);
  };

  const handleAddWorkspaceModalClose = () => {
    setShowWorkspaceModal(false);
  };

  const [collection, setCollection] = useState();

  useEffect(() => {
    if (companyListFromReducer?.length) {
      setCollection(companyListFromReducer?.slice(0, countPerPage));
    }
  }, [companyListFromReducer]);

  const searchData = useRef(
    throttle((val) => {
      const query = val.trim().toLowerCase();
      setCurrentPage(1);
      const data =
        companyListRef?.current?.length &&
        companyListRef?.current
          ?.filter((item) => {
            return item?.name?.toLowerCase().indexOf(query) > -1;
          })
          .slice(0, countPerPage);

      setCollection(data);
    }, 300)
  );

  const updatePage = useCallback(
    (p) => {
      setCurrentPage(p);
      const to = countPerPage * p;
      const from = to - countPerPage;
      setCollection(companyListFromReducer?.slice(from, to));
    },
    [companyListFromReducer]
  );

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <th key={index}>{title}</th>
    ));
  };

  return (
    <Container fluid>
      <div
        className="d-sm-flex"
        style={{
          justifyContent: "space-between",
          marginTop: "10px",
          paddingTop: "10px",
        }}
      >
        <div md={6} className="search">
          <InputField
            className={"did-floating-input"}
            classNameLabel={"did-floating-label"}
            type="text"
            name="lastName"
            label={"Company"}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        {/* <div md={6} className="new-message-btn d-flex gap-2">
            <Tooltip title={"Add Company"}>
              <Button
                className="new-message"
                onClick={handleAddWorkspaceModalShow}
              >
                <i className="fa fa-sitemap" aria-hidden="true"></i>
              </Button>
            </Tooltip>
          </div> */}
      </div>
      {companyListFromReducer?.length === 0 ? (
        <p>No Records Found.</p>
      ) : (
        <>
          <div className="table-responsive">
            <table id="paginationTable">
              <thead id="paginationTableHead">
                <tr id="paginationTableRow">{headRow()}</tr>
              </thead>
              <tbody id="paginationTableBody" className="trhover">
                {collection?.length === 0 ? (
                  <tr>
                    <td>No Records Found</td>
                  </tr>
                ) : (
                  collection?.length &&
                  collection?.map((company, index) => {
                    return (
                      <tr key={company?.id}>
                        <td>{company?.name}</td>
                        <td>{company?.type}</td>
                        <td>{company?.defaultDatasetStorageFormat}</td>
                        <td>{company?.id}</td>
                        {/*    <td>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "24px",
                              }}
                            >
                              <Tooltip title="Remove Company Detail">
                                <Link
                                  onClick={() => {
                                    rowDeleteHandler(company?.id);
                                  }}
                                >
                                  <i
                                    style={{ color: "#3a49a1" }}
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </Link>
                              </Tooltip>
                              <Tooltip title="Edit Company Detail">
                                <Link
                                  onClick={() => {
                                    rowEditHandler(company?.id);
                                  }}
                                >
                                  <img
                                    src="/assets/images/editIcon.svg"
                                    className="img-fluid"
                                    alt="icon"
                                  />
                                </Link>
                              </Tooltip>
                            </div>
                          </td> */}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            showTitle={false}
            className="paginate-sec"
            pageSize={countPerPage}
            onChange={updatePage}
            current={currentPage}
            showLessItems={true}
            total={companyListFromReducer.length}
          />
        </>
      )}
      <ModalComponent
        show={showDeleteModal}
        handleClose={handleDeleteModalClose}
        keyboard={true}
        size="modal-sm"
      >
        <div className="delete-modal">
          <i className="fa fa-times" aria-hidden="true"></i>
          <h4>Remove</h4>
          <p>Are you sure you want to Remove this Company ?</p>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <Button className="white-back" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button className="red-back" onClick={deleteWorkspaceHandler}>
              Remove
            </Button>
          </div>
        </div>
      </ModalComponent>
      <ModalWrapper
        keyboard={true}
        showModal={showEditUserModal}
        handleClose={handleEditWorkspaceModalClose}
        title={`Update Company`}
        size="lg"
      >
        <SuperAdminEditCompany
          companyId={companyId}
          handleEditWorkspaceModalClose={handleEditWorkspaceModalClose}
        />
      </ModalWrapper>
      <ModalComponent
        show={showDeleteSuccessModal}
        handleClose={handleDeleteSuccessModalClose}
        size="modal-sm"
      >
        <div className="delete-modal">
          <img
            src="/assets/images/Checkmark.png"
            className="img-fluid"
            alt="logo"
          />
          <h4 className="mb-3">Company Removed Successfully</h4>
          <p>The Company has been removed successfully.</p>
        </div>
      </ModalComponent>
      <ModalWrapper
        showModal={showAddWorkspaceModal}
        handleClose={handleAddWorkspaceModalClose}
        title="Add Company"
        keyboard={true}
        size="lg"
      >
        <SuperAdminAddCompany
          handleAddWorkspaceModalClose={handleAddWorkspaceModalClose}
        />
      </ModalWrapper>
      <Spinner isLoading={loading || companyLoading || reportLoading} />
    </Container>
  );
};

export default CompanyListForSuperAdmin;
