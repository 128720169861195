import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import "react-phone-number-input/style.css";

import { Container, Form, Row, Col } from "react-bootstrap";
import { CompanyAdminEditUserSchema } from "../../utils/validationSchema/validation";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CityData from "../../utils/data/city.json";
import StateData from "../../utils/data/states.json";
import Select, { components } from "react-select";
import {
  editUserByUserId,
  getAllUsersByCompanyId,
  getUserById,
} from "../../redux/slice/userSlice";
import dayjs from "dayjs";
import { firstCapitalLetter } from "../../utils/commonFunction";
import {
  genderOptions,
  CompanyAdminRoleOptions,
} from "../../utils/data/staticData";
import { getUpnNamesByCompanyId } from "../../redux/slice/upnSlice";
import InputField from "../../components/layout/shared/InputField";
import Spinner from "../../components/layout/shared/Spinner";
import {
  colourStylesState,
  ControlAstrisk,
  multiColorStyles,
} from "../../configs/stylesConfig";
import moment from "moment";
import { getJobTitlesByCompanyId } from "../../redux/slice/jobTitleSlice";
import { toast } from "react-toastify";

const defaultValues = {
  firstName: "",
  lastName: "",
  date_of_birth: new Date(),
  user_principal: [],
  company: {
    name: "",
    id: "",
  },
  role: {
    label: "",
    value: "",
  },
  job_title: {
    label: "",
    value: "",
  },
  mobile: "",
  email: "",
  comments: "",
  country: "",
  gender: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  terms: false,
};

const CompanyAdminEditUser = (props) => {
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const dispatch = useDispatch();
  const [userPrincipalOptions, setUserPrincipalOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);

  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [isCityDisabled, setIsCityDisabled] = useState(true);

  const toggleShowNewPassword = () => {
    setShowNewPassword((prev) => !prev);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmNewPassword((prev) => !prev);
  };

  const currentUser = useSelector(({ user }) => user.user);

  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);

  const userId = props?.userId;

  const jobTitleListFromReducer = useSelector(
    ({ jobTitle }) => jobTitle.jobTitlesList
  );

  const jobTitleLoading = useSelector(
    ({ jobTitle }) => jobTitle.jobTitleLoading
  );

  useEffect(() => {
    if (jobTitleListFromReducer) {
      const loadOptions = jobTitleListFromReducer.map((el) => {
        return {
          value: el?.job_title,
          label: el?.job_title,
        };
      });
      setJobTitleOptions(loadOptions);
    }
  }, [jobTitleListFromReducer]);

  useEffect(() => {
    if (userId) {
      dispatch(getUserById(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (loggedInUser?.company) {
      setValue("company", {
        name: loggedInUser?.company?.company_name,
        id: loggedInUser?.company?.company_id,
      });
      dispatch(getUpnNamesByCompanyId(loggedInUser?.company?.company_id)).then(
        (response) => {
          const data = response.payload;

          const loadOptions = data?.map((el) => {
            return {
              value: el?.user_principal_name,
              label: el.user_principal_name,
            };
          });

          setUserPrincipalOptions(loadOptions);
        }
      );
    }
  }, [dispatch, loggedInUser?.company]);

  useEffect(() => {
    if (!currentUser) return;

    const data = {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      email: currentUser?.email,
      mobile: currentUser?.mobile,
      job_title: {
        label: currentUser?.job_title,
        value: currentUser?.job_title,
      },
      company: {
        name: currentUser?.company?.company_name,
        id: currentUser?.company?.company_id,
      },

      user_principal: [],
      role: {
        label: currentUser?.role?.[0],
        value: currentUser?.role?.[0],
      },

      date_of_birth: currentUser?.dob
        ? moment(currentUser?.dob, "DD/MM/YYYY").format("MM/DD/YYYY")
        : new Date(),
      gender: {
        label: currentUser?.gender
          ? firstCapitalLetter(currentUser?.gender)
          : "",
        value: currentUser?.gender ? currentUser?.gender : "",
      },
      address1: currentUser?.address1,
      address2: currentUser?.address2,
      city: { label: currentUser?.city, value: currentUser?.city },
      state: { name: currentUser?.state, value: currentUser?.state },
      country: currentUser?.country,
      zipcode: currentUser?.zipcode,

      terms: currentUser?.terms,
    };

    setSelectedState({ name: currentUser?.state, value: currentUser?.state });

    reset({
      ...data,
    });
  }, [currentUser]);

  const {
    handleSubmit,
    watch,
    register,
    control,
    setValue,
    setError,
    handleBlur,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(CompanyAdminEditUserSchema),
  });

  const form = watch();

  const onSubmit = async (data) => {
    setLoading(true);

    const postData = {
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      dob: data?.date_of_birth
        ? moment(data?.date_of_birth, "MM/DD/YYYY").format("DD/MM/YYYY")
        : new Date(),
      email: data?.email && data.email.toLowerCase(),
      mobile: data?.mobile || "",
      user_principal: data?.user_principal?.map((item) => item.value),
      job_title: data?.job_title?.value,
      company: {
        company_name: data?.company?.name,
        company_id: data?.company?.id,
      },
      gender: data?.gender?.value,
      address1: data?.address1,
      address2: data?.address2,
      state: data?.state?.name,
      city: data?.city?.value,
      country: data?.country,
      role: data?.role?.value,
      zipcode: data?.zipcode,
    };

    if (data?.confirm_password) {
      postData.password = data?.confirm_password;
    }

    const params = { postData, userId: userId };

    try {
      const userData = await dispatch(editUserByUserId(params));

      if (!userData?.payload || userData?.payload === undefined) {
        setLoading(false);
        return;
      } else {
        dispatch(getAllUsersByCompanyId(loggedInUser?.company?.company_id));
        toast.success("User has been updated successfully");

        setLoading(false);
        props?.handlePageAfterEdit();
        props?.handleEditUserModalClose();
      }
    } catch (error) {
      // Handle the error here, you can show an error message or log it.
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      loggedInUser?.company?.company_id !== undefined &&
      loggedInUser?.company?.company_id !== ""
    ) {
      setLoading(true);

      dispatch(getJobTitlesByCompanyId(loggedInUser?.company?.company_id))
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [loggedInUser?.company]);

  useEffect(() => {
    if (selectedState) {
      const dataFromCity = CityData.filter((city) => {
        return city.state === selectedState.name;
      })?.map((city) => {
        return {
          value: city.label,
          label: city.label,
        };
      });

      setCityOptions(dataFromCity);
      setIsCityDisabled(false); // Enable city dropdown when state is selected
    } else {
      setCityOptions([]);
      setIsCityDisabled(true); // Disable city dropdown when no state is selected
    }
  }, [selectedState]);

  return (
    <section className="">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Container>
              <Row className="align-items-center">
                <Col md="12" className="m-auto">
                  <div className="shadow-box p-0">
                    <Row className="align-items-center">
                      <Col md={12}>
                        <div className="login-form mt-0">
                          <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="firstName"
                                >
                                  <Controller
                                    control={control}
                                    name="firstName"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type="text"
                                        name="firstName"
                                        label={"First Name"}
                                        value={field?.value}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.firstName && (
                                    <Form.Text className="text-danger">
                                      {errors.firstName.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="lastName"
                                >
                                  <Controller
                                    control={control}
                                    name="lastName"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type="text"
                                        name="lastName"
                                        label={"Last Name"}
                                        value={field?.value}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.lastName && (
                                    <Form.Text className="text-danger">
                                      {errors.lastName.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Controller
                                    control={control}
                                    name="email"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type="email"
                                        name="email"
                                        label={"Email Address"}
                                        value={field?.value}
                                        onChange={field.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.email && (
                                    <Form.Text className="text-danger">
                                      {errors.email.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md="6">
                                <Form.Group
                                  className="mb-3 did-floating-label-content"
                                  controlId="mobile"
                                >
                                  <Controller
                                    name="mobile"
                                    control={control}
                                    rules={{
                                      validate: (value) =>
                                        value === "" ||
                                        isValidPhoneNumber(value),
                                    }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <PhoneInput
                                        className={"did-floating-input"}
                                        label="Mobile"
                                        value={value}
                                        onChange={(e) => {
                                          onChange(e);
                                          setError("mobile", null);
                                        }}
                                        defaultCountry="US"
                                        id="mobile"
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />
                                  <label className="did-floating-label">
                                    Mobile
                                  </label>

                                  {errors.mobile && (
                                    <Form.Text className="text-danger">
                                      {errors.mobile.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="company"
                                >
                                  <Controller
                                    control={control}
                                    name="company"
                                    render={({ field }) => (
                                      <Select
                                        isDisabled={true}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          setValue("user_principal", []);
                                        }}
                                        placeholder={" "}
                                        value={field.value}
                                        disabled={true}
                                        styles={colourStylesState}
                                        isClearable={false}
                                        label={"Company"}
                                        components={{
                                          Control: ControlAstrisk,
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    )}
                                  />

                                  {errors?.company && (
                                    <Form.Text className="text-danger">
                                      {errors?.company?.name?.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3 did-floating-label-content"
                                  controlId="role"
                                >
                                  <Controller
                                    control={control}
                                    name="role"
                                    render={({ field }) => (
                                      <Select
                                        className=" "
                                        options={CompanyAdminRoleOptions}
                                        onChange={field.onChange}
                                        placeholder={" "}
                                        value={field.value}
                                        isClearable={true}
                                        styles={colourStylesState}
                                        label={"User Type"}
                                        components={{
                                          Control: ControlAstrisk,
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    )}
                                  />

                                  {errors.role && (
                                    <Form.Text className="text-danger">
                                      {errors?.role?.label?.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Form.Group
                                  className="mb-3 did-floating-label-content"
                                  controlId="job_title"
                                >
                                  <Controller
                                    control={control}
                                    name="job_title"
                                    render={({ field }) => (
                                      <Select
                                        className=" "
                                        options={jobTitleOptions}
                                        onChange={(selectedOption) => {
                                          field.onChange(selectedOption);
                                          const upOption =
                                            jobTitleListFromReducer?.find(
                                              (el) =>
                                                el?.job_title ===
                                                selectedOption?.value
                                            );

                                          const loadOptions =
                                            upOption?.user_principal?.map(
                                              (el) => ({
                                                value: el,
                                                label: el,
                                              })
                                            );

                                          setValue(
                                            "user_principal",
                                            loadOptions || []
                                          );
                                        }}
                                        placeholder={" "}
                                        value={field.value}
                                        styles={colourStylesState}
                                        isClearable={true}
                                        label={"Job Title"}
                                        components={{
                                          Control: ControlAstrisk,
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    )}
                                  />
                                  {errors?.job_title && (
                                    <Form.Text className="text-danger">
                                      {errors?.job_title?.label?.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              {/* <Col md="6">
                                <Form.Group className="did-floating-label-content mb-3">
                                  <Controller
                                    control={control}
                                    name="user_principal"
                                    render={({ field }) => (
                                      <Select
                                        className=" "
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        placeholder={" "}
                                        required={true}
                                        styles={multiColorStyles}
                                        isSearchable={true}
                                        options={userPrincipalOptions}
                                        components={{
                                          Control,
                                          MultiValue,
                                        }}
                                        value={field?.value}
                                        onChange={(event) => {
                                          if (!event) {
                                            field.onChange(event);
                                          }
                                          if (
                                            event &&
                                            event.length > 0 &&
                                            event.some(
                                              (item) => item.value === "all"
                                            )
                                          ) {
                                            // If "Select All" is selected, set all options as selected
                                            field.onChange(
                                              userPrincipalOptions
                                                .map((value) => value)
                                                .filter(
                                                  (x) => x.value !== "all"
                                                )
                                            );
                                          } else {
                                            field.onChange(
                                              event.map((value) => value)
                                            );
                                          }
                                        }}
                                        label={"Roles"}
                                      />
                                    )}
                                  />

                                  {errors.user_principal && (
                                    <Form.Text className="text-danger">
                                      {errors.user_principal.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col> */}
                            </Row>
                            <Row>
                              <Col md="6">
                                <Form.Group
                                  className="mb-3 did-floating-label-content"
                                  controlId="date_of_birth"
                                >
                                  <Controller
                                    control={control}
                                    name="date_of_birth"
                                    render={({ field }) => (
                                      <ReactDatePicker
                                        className="did-floating-input"
                                        dateFormat="MM/DD/YYYY"
                                        placeholderText={"MM/DD/YYYY"}
                                        onChange={(value) =>
                                          field.onChange(
                                            dayjs(value).format("MM/DD/YYYY")
                                          )
                                        }
                                        value={field.value}
                                        defaultValues={null}
                                        maxDate={new Date()}
                                        isClearable={true}
                                        showYearDropdown // year show and scrolldown alos
                                        scrollableMonthYearDropdown={true}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />
                                  <label
                                    className={"did-floating-label-datepicker"}
                                  >
                                    Date of Birth
                                  </label>
                                  {errors.date_of_birth && (
                                    <Form.Text className="text-danger">
                                      {errors.date_of_birth.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="mb-3 did-floating-label-content"
                                  controlId="gender"
                                >
                                  <Controller
                                    control={control}
                                    name="gender"
                                    render={({ field }) => (
                                      <Select
                                        className=""
                                        options={genderOptions}
                                        onChange={field.onChange}
                                        placeholder={""}
                                        value={field.value}
                                        styles={colourStylesState}
                                        isClearable={true}
                                        label={"Gender"}
                                        components={{
                                          Control: Control,
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    )}
                                  />

                                  {errors.gender && (
                                    <Form.Text className="text-danger">
                                      {errors.gender.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <Form.Group
                                  className="mb-3 did-floating-label-content"
                                  controlId="address1"
                                >
                                  <Controller
                                    control={control}
                                    name="address1"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        type={"text"}
                                        name="address1"
                                        label={"Address 1"}
                                        value={field?.value}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.address1 && (
                                    <Form.Text className="text-danger">
                                      {errors.address1.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md="6">
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="address2"
                                >
                                  <Controller
                                    control={control}
                                    name="address2"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        type={"text"}
                                        name="address2"
                                        label={"Address 2"}
                                        value={field?.value}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.address2 && (
                                    <Form.Text className="text-danger">
                                      {errors.address2.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-3" controlId="state">
                                  <Controller
                                    control={control}
                                    name="state"
                                    render={({ field }) => (
                                      <Select
                                        options={StateData}
                                        label={"State"}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.name}
                                        onChange={(value) => {
                                          field.onChange(value);
                                          setSelectedState(value);
                                          setValue("city", "");
                                        }}
                                        value={field.value}
                                        placeholder=" "
                                        isSearchable={true}
                                        isClearable={true}
                                        styles={colourStylesState}
                                        components={{
                                          Control,
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    )}
                                  />

                                  {errors.state && (
                                    <Form.Text className="text-danger">
                                      {errors.state.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-3 " controlId="city">
                                  <Controller
                                    control={control}
                                    name="city"
                                    render={({ field }) => (
                                      <Select
                                        options={cityOptions}
                                        isDisabled={isCityDisabled}
                                        label={"City"}
                                        onChange={field.onChange}
                                        placeholder={" "}
                                        value={field.value}
                                        isSearchable={true}
                                        isClearable={true}
                                        styles={colourStylesState}
                                        components={{
                                          Control,
                                          DropdownIndicator: () => null,
                                          IndicatorSeparator: () => null,
                                        }}
                                      />
                                    )}
                                  />

                                  {errors.city && (
                                    <Form.Text className="text-danger">
                                      {errors.city.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="country"
                                >
                                  <Controller
                                    control={control}
                                    name="country"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={"did-floating-label"}
                                        type="text"
                                        name="country"
                                        label={"Country"}
                                        value={"USA"}
                                        disabled={true}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.country && (
                                    <Form.Text className="text-danger">
                                      {errors.country.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="zipcode"
                                >
                                  <Controller
                                    control={control}
                                    name="zipcode"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={"did-floating-label"}
                                        type="number"
                                        name="zipcode"
                                        label={"Zipcode"}
                                        value={field?.value}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.zipcode && (
                                    <Form.Text className="text-danger">
                                      {errors.zipcode.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="new_password"
                                >
                                  <Controller
                                    control={control}
                                    name="new_password"
                                    render={({ field }) => (
                                      <input
                                        className="did-floating-input"
                                        placeholder=" "
                                        type={
                                          showNewPassword ? "text" : "password"
                                        }
                                        value={field?.value}
                                        onChange={field?.onChange}
                                      />
                                    )}
                                  />
                                  <label className="did-floating-label">
                                    New Password
                                  </label>
                                  {showNewPassword ? (
                                    <span
                                      onClick={toggleShowNewPassword}
                                      id="#password-field"
                                      style={{
                                        color: "#24a5a5",
                                        float: "right",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        position: "relative",
                                        right: "10px",
                                        zIndex: "2",
                                        cursor: "pointer",
                                      }}
                                      className="fa fa-fw fa-eye field-icon toggle-password"
                                    ></span>
                                  ) : (
                                    <span
                                      onClick={toggleShowNewPassword}
                                      style={{
                                        color: "#24a5a5",
                                        float: "right",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        position: "relative",
                                        right: "10px",
                                        cursor: "pointer",
                                        zIndex: "2",
                                      }}
                                      id="#password-field"
                                      className="fa fa-fw fa-eye-slash field-icon toggle-password"
                                    ></span>
                                  )}
                                  {errors.new_password && (
                                    <Form.Text className="text-danger">
                                      {errors.new_password.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="confirm_password"
                                >
                                  <Controller
                                    control={control}
                                    name="confirm_password"
                                    render={({ field }) => (
                                      <input
                                        className="did-floating-input"
                                        placeholder=" "
                                        type={
                                          showConfirmNewPassword
                                            ? "text"
                                            : "password"
                                        }
                                        value={field?.value}
                                        onChange={field?.onChange}
                                      />
                                    )}
                                  />
                                  <label className="did-floating-label">
                                    Confirm Password
                                  </label>
                                  {showConfirmNewPassword ? (
                                    <span
                                      onClick={toggleShowConfirmPassword}
                                      id="#password-field"
                                      style={{
                                        color: "#24a5a5",
                                        float: "right",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        position: "relative",
                                        right: "10px",
                                        zIndex: "2",
                                        cursor: "pointer",
                                      }}
                                      className="fa fa-fw fa-eye field-icon toggle-password"
                                    ></span>
                                  ) : (
                                    <span
                                      onClick={toggleShowConfirmPassword}
                                      style={{
                                        color: "#24a5a5",
                                        float: "right",
                                        marginLeft: "-25px",
                                        marginTop: "8px",
                                        position: "relative",
                                        right: "10px",
                                        zIndex: "2",
                                        cursor: "pointer",
                                      }}
                                      id="#password-field"
                                      className="fa fa-fw fa-eye-slash field-icon toggle-password"
                                    ></span>
                                  )}
                                  {errors.confirm_password && (
                                    <Form.Text className="text-danger">
                                      {errors.confirm_password.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <Form.Group controlId="policycheck">
                                  <Form.Check
                                    type="checkbox"
                                    label="I agree to privacy policy & terms"
                                    {...register("terms", {
                                      required:
                                        "You must accept the privacy policy & terms",
                                    })}
                                  />
                                  {errors.terms && (
                                    <Form.Text className="text-danger">
                                      {errors.terms.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>

                            <div>
                              <button
                                className={`login-btn w-100 m-0 ${
                                  loading ? "disabled" : ""
                                }`}
                                type="submit" // Change to type="submit" to trigger form submission
                                disabled={loading} // Disable the button while loading
                              >
                                Update User
                              </button>
                            </div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Spinner isLoading={loading} />
    </section>
  );
};
export default CompanyAdminEditUser;

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? props?.selectProps?.isRequired
              ? "did-floating-label did-floating-label-top did-floating-labe-astrisk"
              : "did-floating-label did-floating-label-top"
            : props?.selectProps?.isRequired
            ? "did-floating-label did-floating-label did-floating-labe-astrisk"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 100 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};
const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#24a5a5",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    color: "#fff",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};
const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 2;
  const overflow = getValue()
    ?.slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
