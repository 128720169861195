import { useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { Container, Form, Row, Col } from "react-bootstrap";
import { companyInfoInfoSchema } from "../../utils/validationSchema/validation";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../components/layout/shared/InputField";
import {
  editCompanyByCompanyId,
  getCompanyById,
  getCompanyList,
} from "../../redux/slice/companySlice";
import { toast } from "react-toastify";
import Spinner from "../../components/layout/shared/Spinner";

const defaultValues = {
  company: "",
  lastName: "",
  companyName: "",
  companyType: "",
  mobile: "",
  email: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  terms: false,
};

const SuperAdminEditCompany = (props) => {
  const dispatch = useDispatch();

  const currentCompany = useSelector(({ company }) => company.company);
  const [loading, setLoading] = useState(false);

  const companyId = props?.companyId;


  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyById(companyId));
    }
  }, [dispatch, companyId]);

  const {
    handleSubmit,
    watch,
    register,
    handleBlur,
    control,
    reset,
    methods,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(companyInfoInfoSchema),
  });

  useEffect(() => {
    if (!currentCompany) return;

    const data = {
      name: currentCompany?.name,
    };

    reset({
      ...data,
    });
  }, [currentCompany, methods]);



  const onSubmit = async (data) => {
    setLoading(true);

    const postData = {
      name: data?.name,
      groupId: currentCompany?.id,
      defaultDatasetStorageFormat: currentCompany?.defaultDatasetStorageFormat,
    };

    if (data?.confirm_password) {
      postData.password = data?.confirm_password;
    }

    const params = { postData, companyId: companyId };


  try {
    const userData = await dispatch(editCompanyByCompanyId(params));

    if (!userData?.payload || userData?.payload === undefined) {
      setLoading(false);
      return;
    } else {
      props?.handleEditWorkspaceModalClose();
      setLoading(false);
      toast.success("Company has been updated successfully");
      dispatch(getCompanyList());
    }
  } catch (error) {
    // Handle the error here, for example, log the error or display an error message.
    console.error("An error occurred:", error);
    // Optionally, you can also set setLoading to false here if needed.
    setLoading(false);
  }

  };
  return (
    <section className="">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Container>
              <Row className="align-items-center">
                <Col md="12" className="m-auto">
                  <div className="shadow-box p-0">
                    <Row className="align-items-center">
                      <Col md={12}>
                        <div className="login-form mt-0">
                          <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                              <Col md={12}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="name"
                                >
                                  <Controller
                                    control={control}
                                    name="name"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type="text"
                                        name="name"
                                        label={"Company Name"}
                                        value={field?.value}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.name && (
                                    <Form.Text className="text-danger">
                                      {errors.name.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>

                            <div>
                              <button className="login-btn w-100  m-0">
                                Update Company
                              </button>
                            </div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Spinner isLoading={loading} />
    </section>
  );
};

export default SuperAdminEditCompany;
