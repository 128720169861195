/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  DELETEUPNDATA,
  GETALLUPN,
  GETUPNBYCOMPANYID,
  GETUPNBYID,
  POSTADDUPN,
  PUTUPNDATA,
} from "../../services/upnService";

export const getAllUPNNames = createAsyncThunk(
  "upn/getAllUPNNames",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETALLUPN(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addUpn = createAsyncThunk(
  "upn/addUpn",
  async (upnData, { rejectWithValue }) => {
    try {
      const response = await POSTADDUPN(upnData);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const editUpnByUpnId = createAsyncThunk(
  "upn/editUpnByUpnId",
  async (params, { rejectWithValue }) => {
    try {
      const response = await PUTUPNDATA(params);

      return response?.data;
    } catch (error) {
      // return rejectWithValue(error?.response?.data)
    }
  }
);

export const getUpnNamesByCompanyId = createAsyncThunk(
  "upn/getUpnNamesByCompanyId",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await GETUPNBYCOMPANYID(companyId);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUpnById = createAsyncThunk(
  "upn/getUpnById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await GETUPNBYID(params);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);
export const deleteUpnById = createAsyncThunk(
  "upn/deleteUpnById",
  async (params, { rejectWithValue }) => {
    try {
      const response = await DELETEUPNDATA(params);

      return response;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

const initialState = {
  userPrincipal: null,
  userPrincipalNameList: [],
  upnListForSuperAdmin: [],
  upnLoading: false,
};

const upnSlice = createSlice({
  name: "upn",
  initialState: initialState,

  extraReducers: (builder) => {
    builder.addCase(addUpn.fulfilled, (state, action) => {
      state.userPrincipal = action?.payload && action?.payload?.data;
    });
    builder.addCase(getUpnById.fulfilled, (state, action) => {
      state.userPrincipal = action?.payload && action?.payload;
    });
    builder.addCase(getAllUPNNames.pending, (state, action) => {
      state.upnLoading = true;
      state.userPrincipalNameList = [];
    });

    builder.addCase(getAllUPNNames.fulfilled, (state, action) => {
      state.upnLoading = false;
      state.userPrincipalNameList = action.payload?.data?.value;
    });
    builder.addCase(getAllUPNNames.rejected, (state, action) => {
      state.upnLoading = false;
      state.userPrincipalNameList = [];
    });
    builder.addCase(getUpnNamesByCompanyId.fulfilled, (state, action) => {
      state.userPrincipalNameList = action?.payload;
    });
    builder.addCase(deleteUpnById.fulfilled, (state, action) => {
      state.userPrincipal = action?.payload;
    });
  },
});

export default upnSlice.reducer;
