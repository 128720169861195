// ** React Imports
import { useState } from "react";

import { useForm, Controller } from "react-hook-form";

// ** Third Party Imports
import { yupResolver } from "@hookform/resolvers/yup";


import { Container, Form, Row, Col } from "react-bootstrap";
import { companyInfoInfoSchema } from "../../utils/validationSchema/validation";
import InputField from "../../components/layout/shared/InputField";
import { useDispatch } from "react-redux";
import { addCompany, getCompanyList } from "../../redux/slice/companySlice";
import { toast } from "react-toastify";
import Spinner from "../../components/layout/shared/Spinner";

const defaultValues = {
  name: "",
  terms: false,
};

const SuperAdminAddCompany = (props) => {
  // ** States

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    watch,
    handleBlur,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(companyInfoInfoSchema),
  });

  const dispatch = useDispatch();


  const onSubmit = async (data) => {
    setLoading(true);
    const params = {
      name: data?.name,
    };

try {
  const userData = await dispatch(addCompany(params));

  if (!userData?.payload || userData?.payload === undefined) {
    setLoading(false);
    return;
  } else {
    dispatch(getCompanyList());
    toast.success("Company has been created successfully");
    props?.handleAddWorkspaceModalClose();
  }
} catch (error) {
  // Handle the error here, you can show an error message or log it.
  console.error("An error occurred:", error);
} finally {
  setLoading(false);
}

  };

  return (
    <section className="">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Container>
              <Row className="align-items-center">
                <Col md="12" className="m-auto">
                  <div className="shadow-box p-0">
                    <Row className="align-items-center">
                      <Col md={12}>
                        <div className="login-form mt-0">
                          <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                              <Col md={12}>
                                <Form.Group
                                  className="did-floating-label-content mb-3"
                                  controlId="name"
                                >
                                  <Controller
                                    control={control}
                                    name="name"
                                    render={({ field }) => (
                                      <InputField
                                        className={"did-floating-input"}
                                        classNameLabel={
                                          "did-floating-label did-floating-labe-astrisk"
                                        }
                                        type="text"
                                        name="name"
                                        label={"Company Name"}
                                        value={field?.value}
                                        onChange={field?.onChange}
                                        onBlur={handleBlur}
                                      />
                                    )}
                                  />

                                  {errors.name && (
                                    <Form.Text className="text-danger">
                                      {errors.name.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>

                            <div>
                              <button className="login-btn w-100  m-0">
                                Add Company
                              </button>
                            </div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Spinner isLoading={loading}/>
    </section>
  );
};

export default SuperAdminAddCompany;
