import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/layout/shared/Spinner";
import { Tooltip } from "@mui/material";
import ModalComponent from "../../components/layout/shared/ModalComponent";
import ModalWrapper from "../../components/layout/shared/ModalWrapper";
import { Button, Container } from "react-bootstrap";
import { firstCapitalLetter } from "../../utils/commonFunction";
import CompanyAdminAddUser from "./CompanyAdminAddUser";
import CompanyAdminInviteUserForm from "./CompanyAdminInviteUserForm";
import CompanyAdminImportUsersForm from "./CompanyAdminImportUsersForm";
import CompanyAdminEditUser from "./CompanyAdminEditUser";
import PaginationEllipsis from "../../components/layout/shared/PaginationEllipsis";
import { getAllUsersByCompanyId } from "../../redux/slice/userSlice";
import { toggleUserActive } from "../../redux/slice/authSlice";
import { REACT_APP_BACKEND_URL } from "../../configs/config";
import InputField from "../../components/layout/shared/InputField";
import { POSTDELETEUSER, POSTREINVITEUSER } from "../../services/userService";
import { toast } from "react-toastify";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
} from "../../configs/config";

const UserListForCompanyAdmin = (props) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);
  const userLoading = useSelector(({ user }) => user.loading);
  const userListRef = useRef();
  const userListFromReducer = useSelector(({ user }) => user.userList);
  userListRef.current = userListFromReducer?.length && userListFromReducer;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getAllUsersByCompanyId(loggedInUser?.company?.company_id))
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);

  const tableHead = {
    name: "Name",
    email: "Email",
    country: "Country",
    gender: "Gender",
    company: "Company",
    job_title: "Job Title",
    userType: "User Type",
    action: "Actions",
  };

  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const maxVisiblePages = 5;
  const [showReinviteUserModal, setShowReInviteUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false);
  const [userId, setUserId] = useState("");

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const downloadFileWithAuthorization = async (fileUrl, accessToken) => {
    setIsExportButtonDisabled(true);
    try {
      const headers = {
        Authorization: `${accessToken}`,
        // Add any other headers if required
      };

      const response = await fetch(fileUrl, {
        method: "GET",
        headers,
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = "List of Users"; // Specify the filename for the downloaded file

        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to download the file.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsExportButtonDisabled(false);
    }
  };

  const downloadExcel = () => {
    setIsExportButtonDisabled(true);
    const encryptedToken = localStorage.getItem("accessToken");
    const token =
      encryptedToken &&
      CryptoJS.AES.decrypt(encryptedToken, REACT_APP_CRYPTOJS_SECRETKEY, {
        REACT_APP_CRYPTOJS_VECTOR,
      }).toString(CryptoJS.enc.Utf8);

    downloadFileWithAuthorization(
      `${REACT_APP_BACKEND_URL}/auth/exportUsers`,
      token
    );
    setIsExportButtonDisabled(false);
  };

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(userListFromReducer.length / countPerPage);
    const items = [];

    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <li
          key={i}
          className={
            i === currentPage
              ? "rc-pagination-item rc-pagination-item-active"
              : "rc-pagination-item"
          }
          onClick={() => updatePage(i)}
        >
          {i}
        </li>
      );
    }

    if (totalPages > maxVisiblePages) {
      if (currentPage > maxVisiblePages - 2) {
        items.splice(1, 0, <PaginationEllipsis key="ellipsis" />);
      }
      if (currentPage < totalPages - 2) {
        items.splice(-1, 0, <PaginationEllipsis key="ellipsis" />);
      }
    }

    return items;
  };

  const resendInviteHandler = async () => {
    setLoading(true);
    let success = false;

    try {
      const inviteResponse = await POSTREINVITEUSER({ id: userId });

      if (
        inviteResponse?.data?.accepted?.length > 0 &&
        inviteResponse?.status === 200
      ) {
        success = true;
        dispatch(getAllUsersByCompanyId(loggedInUser?.company?.company_id));
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      setShowReInviteUserModal(false);

      if (success) {
        toast.success("Invitation mail sent successfully!");
      }
    }
  };
  const handleAddUserModalShow = () => {
    setShowAddUserModal(true);
  };

  const handleAddUserModalClose = () => {
    setShowAddUserModal(false);
  };

  const [showInviteModal, setShowInviteModal] = useState(false);

  const handleInviteModalShow = () => {
    setShowInviteModal(true);
  };

  const handleInviteModalClose = () => {
    setShowInviteModal(false);
  };

  const rowReInviteHandler = (user_id) => {
    setUserId(user_id);
    handleReinviteModalShow(user_id);
  };

  const handleReinviteModalShow = () => {
    setShowReInviteUserModal(true);
  };

  const handleReinviteModalClose = (stId) => {
    setShowReInviteUserModal(false);
  };

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const handleFileUploadModalShow = () => {
    setShowFileUploadModal(true);
  };

  const handleFileUploadModalClose = () => {
    setShowFileUploadModal(false);
  };

  const handlePageAfterEdit = () => {
    setCurrentPage(1);
  };

  const [collection, setCollection] = useState(
    userListFromReducer?.slice(0, countPerPage)
  );

  useEffect(() => {
    if (userListFromReducer) {
      setCollection(userListFromReducer?.slice(0, countPerPage));
    }
  }, [userListFromReducer]);

  const handleEditUserModalShow = (userId) => {
    setUserId(userId);
    setShowEditUserModal(true);
  };

  const handleEditUserModalClose = (userId) => {
    setShowEditUserModal(false);
  };

  const handleDeleteModalShow = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = (stId) => {
    // setStudentId(stId);
    setShowDeleteModal(false);
  };

  const deleteUserHandler = async () => {
    setLoading(true);
    let success = false;

    try {
      const deletedResponse = await POSTDELETEUSER({
        id: userId,
        isDeleted: true,
      });

      if (deletedResponse?.data?.isDeleted) {
        success = true;
        dispatch(getAllUsersByCompanyId(loggedInUser?.company?.company_id));
      }
    } catch (error) {
      // Handle the error here, for example, log the error message or show a notification to the user.
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);

      if (success) {
        toast.success("User has been removed successfully");
      }
    }
  };

  const rowDeleteHandler = (user_id) => {
    setUserId(user_id);
    handleDeleteModalShow(user_id);
  };

  const rowEditHandler = (userId) => {
    handleEditUserModalShow(userId);
  };

  const searchData = useRef(
    throttle((val) => {
      const query = val?.trim().toLowerCase();
      setCurrentPage(1);
      const data =
        userListRef?.current?.length &&
        userListRef?.current
          ?.filter((item) => {
            const fullName =
              `${item?.firstName} ${item?.lastName}`.toLowerCase();
            return (
              fullName?.indexOf(query) > -1 || item?.email?.indexOf(query) > -1
            );
          })
          ?.slice(0, countPerPage);

      setCollection(data);
    }, 300)
  );

  const updatePage = useCallback(
    (p) => {
      setCurrentPage(p);
      const to = countPerPage * p;
      const from = to - countPerPage;
      setCollection(userListFromReducer?.slice(from, to));
    },
    [userListFromReducer]
  );

  const userToggleHandler = async (user) => {
    setLoading(true);
    const params = {
      id: user?._id,
      active: !user?.active,
    };

    await dispatch(toggleUserActive(params))
      .then((res) => {
        dispatch(getAllUsersByCompanyId(loggedInUser?.company?.company_id));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <th key={index}>{title}</th>
    ));
  };

  return (
    <Container>
      <div
        className="d-md-flex"
        style={{
          justifyContent: "space-between",
          marginTop: "10px",
          paddingTop: "10px",
        }}
      >
        <div md={6} className="search">
          <InputField
            className={"did-floating-input"}
            classNameLabel={"did-floating-label"}
            type="text"
            name="lastName"
            label={"User"}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div md={6} className="new-message-btn d-flex gap-1">
          <Tooltip title={"Invite Users"}>
            <Button className="new-message" onClick={handleInviteModalShow}>
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </Button>
          </Tooltip>
          <Tooltip title={"Import Users"}>
            <Button className="new-message" onClick={handleFileUploadModalShow}>
              <i className="fa fa-upload" aria-hidden="true"></i>
            </Button>
          </Tooltip>
          <Tooltip title={"Export Users"}>
            <Button
              className="new-message"
              onClick={downloadExcel}
              disabled={isExportButtonDisabled}
            >
              <i className="fa fa-download" aria-hidden="true"></i>
            </Button>
          </Tooltip>

          <Tooltip title={"Add User"}>
            <Button className="new-message" onClick={handleAddUserModalShow}>
              <i className="fa fa-user-plus" aria-hidden="true"></i>
            </Button>
          </Tooltip>
        </div>
      </div>
      {userListFromReducer?.length === 0 ? (
        <p>No Records Found.</p>
      ) : (
        <>
          <div className="table-responsive">
            <table id="paginationTable">
              <thead id="paginationTableHead">
                <tr id="paginationTableRow">{headRow()}</tr>
              </thead>
              <tbody id="paginationTableBody" className="trhover">
                {collection?.length === 0 ? (
                  <tr>
                    <td>No Records Found</td>
                  </tr>
                ) : (
                  collection?.length &&
                  collection?.map((user, index) => {
                    return (
                      <tr key={user?._id}>
                        <td>
                          {user?.firstName && user?.lastName
                            ? firstCapitalLetter(user?.firstName) +
                              " " +
                              firstCapitalLetter(user?.lastName)
                            : "-N/A-"}
                        </td>
                        <td>{user?.email ? user?.email : "-N/A-"}</td>
                        <td>
                          {user?.state
                            ? firstCapitalLetter(user?.state)
                            : "-N/A-"}
                        </td>
                        <td>
                          {user?.gender
                            ? firstCapitalLetter(user?.gender)
                            : "-N/A-"}
                        </td>
                        <td>
                          {user?.company?.company_name
                            ? user?.company?.company_name
                            : "-N/A-"}
                        </td>
                        <td>
                          <Tooltip
                            title={user?.job_title ? user?.job_title : "-N/A-"}
                          >
                            {user?.job_title ? user?.job_title : "-N/A-"}
                          </Tooltip>
                        </td>
                        <td>{user?.role ? user?.role?.[0] || "" : "-N/A-"}</td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              gap: "12px",
                              minWidth: "150px",
                              padding: "4px",
                            }}
                          >
                            <Tooltip
                              title={
                                user?.active ? "Disable User" : "Enable User"
                              }
                            >
                              <div className="toggle-btn">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={user?.active}
                                    onChange={() => {
                                      userToggleHandler(user);
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </Tooltip>
                            <Tooltip title="Remove User">
                              <Link
                                onClick={() => {
                                  rowDeleteHandler(user?._id);
                                }}
                              >
                                <i
                                  style={{ color: "#3a49a1" }}
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </Tooltip>
                            <Tooltip title="Edit User Detail">
                              <Link
                                onClick={() => {
                                  rowEditHandler(user?._id);
                                }}
                              >
                                <img
                                  src="/assets/images/editIcon.svg"
                                  className="img-fluid"
                                  alt="icon"
                                  style={{ maxHeight: "18px" }}
                                />
                              </Link>
                            </Tooltip>
                            {user?.invite_url &&
                              user?.invite_url !== "" &&
                              user?.invite_url !== null &&
                              user?.invite_url !== undefined && (
                                <Tooltip title="Resend Invitation">
                                  <Link
                                    onClick={() => {
                                      rowReInviteHandler(user?._id);
                                    }}
                                  >
                                    <i
                                      style={{ color: "#3a49a1" }}
                                      className="fa fa-envelope"
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </Tooltip>
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            showTitle={false}
            className="paginate-sec"
            pageSize={countPerPage}
            onChange={updatePage}
            current={currentPage}
            total={userListFromReducer.length}
            showLessItems={true}
          >
            {renderPaginationItems()}
          </Pagination>
        </>
      )}

      <ModalComponent
        show={showDeleteModal}
        handleClose={handleDeleteModalClose}
        keyboard={true}
        size="md"
      >
        <div className="delete-modal">
          <i className="fa fa-trash" aria-hidden="true"></i>
          <h4>Remove</h4>
          <p>Are you sure you want to Remove this User ?</p>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <Button className="white-back" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button className="red-back" onClick={deleteUserHandler}>
              Remove
            </Button>
          </div>
        </div>
      </ModalComponent>
      <ModalWrapper
        keyboard={true}
        showModal={showEditUserModal}
        handleClose={handleEditUserModalClose}
        title={"Update User"}
        size="lg"
      >
        <CompanyAdminEditUser
          userId={userId}
          handleEditUserModalClose={handleEditUserModalClose}
          handlePageAfterEdit={handlePageAfterEdit}
        />
      </ModalWrapper>

      <ModalWrapper
        showModal={showAddUserModal}
        handleClose={handleAddUserModalClose}
        title="Add User"
        keyboard={true}
        size="lg"
      >
        <CompanyAdminAddUser
          handleAddUserModalClose={handleAddUserModalClose}
          handlePageAfterEdit={handlePageAfterEdit}
        />
      </ModalWrapper>
      <ModalWrapper
        showModal={showInviteModal}
        handleClose={handleInviteModalClose}
        title="Invite User"
        keyboard={true}
        size="md"
      >
        <CompanyAdminInviteUserForm
          handleInviteModalClose={handleInviteModalClose}
          handlePageAfterEdit={handlePageAfterEdit}
        />
      </ModalWrapper>
      <ModalWrapper
        showModal={showFileUploadModal}
        handleClose={handleFileUploadModalClose}
        title="Import Users"
        keyboard={true}
        size="md"
      >
        <CompanyAdminImportUsersForm
          handleFileUploadModalClose={handleFileUploadModalClose}
        />
      </ModalWrapper>
      <ModalComponent
        show={showReinviteUserModal}
        handleClose={handleReinviteModalClose}
        keyboard={true}
        size="md"
      >
        <div className="delete-modal">
          <i className="fa fa-envelope" aria-hidden="true"></i>
          <h4>Resend Invitation</h4>
          <p>Click the button below to resend the invitation!</p>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <Button className="white-back" onClick={handleReinviteModalClose}>
              Cancel
            </Button>
            <Button className="blue-back" onClick={resendInviteHandler}>
              Send
            </Button>
          </div>
        </div>
      </ModalComponent>
      <Spinner isLoading={loading || userLoading} />
    </Container>
  );
};

export default UserListForCompanyAdmin;
