import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import ModalComponent from "../../components/layout/shared/ModalComponent";
import ModalWrapper from "../../components/layout/shared/ModalWrapper";
import SuperAdminAddJobTitle from "./SuperAdminAddJobTitle";
import PaginationEllipsis from "../../components/layout/shared/PaginationEllipsis";
import { getCompanyList } from "../../redux/slice/companySlice";
import Select from "react-select";
import { Control, colourStylesState } from "../../configs/stylesConfig";
import Spinner from "../../components/layout/shared/Spinner";
import InputField from "../../components/layout/shared/InputField";
import { DELETEUPNDATA } from "../../services/upnService.js";
import { toast } from "react-toastify";
import { FaUsersCog } from "react-icons/fa";
import SuperAdminEditJobTitle from "./SuperAdminEditJobTitle.jsx";
import { getJobTitlesByCompanyId } from "../../redux/slice/jobTitleSlice.js";
import { DELETEJOBTITLEDATA } from "../../services/jobTitleService.js";
import { throttle } from "lodash";

const JobTitleListScreen = (props) => {
  const listRef = useRef();
  const jobTitleListFromReducer = useSelector(
    ({ jobTitle }) => jobTitle.jobTitlesList
  );
  const jobTitleLoading = useSelector(
    ({ jobTitle }) => jobTitle.jobTitleLoading
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const companyListFromReducer = useSelector(
    ({ company }) => company.companyList
  );

  listRef.current = jobTitleListFromReducer?.length && jobTitleListFromReducer;

  const tableHead = {
    job_title: "Job Title",
    user_principal: "User Principal Name",
    reports: "Reports",
    action: "Actions",
  };

  const countPerPage = 10;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const maxVisiblePages = 5;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showEditJobTitleModal, setShowEditJobTitleModal] = useState(false);
  const [jobTitleId, setJobTitleId] = useState("");
  const [collection, setCollection] = useState();

  const [showAddJobTitleModal, setShowAddJobTitleModal] = useState(false);
  const reportLoading = useSelector(({ report }) => report.loading);

  const renderPaginationItems = () => {
    const totalPages = Math.ceil(jobTitleListFromReducer.length / countPerPage);
    const items = [];

    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <li
          key={i}
          className={
            i === currentPage
              ? "rc-pagination-item rc-pagination-item-active"
              : "rc-pagination-item"
          }
          onClick={() => updatePage(i)}
        >
          {i}
        </li>
      );
    }

    if (totalPages > maxVisiblePages) {
      if (currentPage > maxVisiblePages - 2) {
        items.splice(1, 0, <PaginationEllipsis key="ellipsis" />);
      }
      if (currentPage < totalPages - 2) {
        items.splice(-1, 0, <PaginationEllipsis key="ellipsis" />);
      }
    }

    return items;
  };

  const handleAddJobTitleModalShow = () => {
    setShowAddJobTitleModal(true);
  };

  const handleAddJobTitleModalClose = () => {
    setShowAddJobTitleModal(false);
  };

  const handleEditJobTitleModalShow = (jobTitleId) => {
    setJobTitleId(jobTitleId);
    setShowEditJobTitleModal(true);
  };

  const handleEditJobTitleModalClose = (jobTitleId) => {
    setShowEditJobTitleModal(false);
  };

  const deleteJobTitleHandler = async () => {
    setLoading(true);
    let success = false;

    try {
      const deletedResponse = await DELETEJOBTITLEDATA(jobTitleId);

      if (deletedResponse?.status === 200) {
        success = true;
        dispatch(getJobTitlesByCompanyId(selectedCompany?.id));
      }
    } catch (error) {
      // Handle the error here, for example, log the error message or show a notification to the user.
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);

      if (success) {
        toast.success("Job Title has been removed successfully");
      }
    }
  };

  const handleDeleteModalShow = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = (stId) => {
    setShowDeleteModal(false);
  };

  const rowDeleteHandler = (jobTitleId) => {
    setJobTitleId(jobTitleId);
    handleDeleteModalShow(jobTitleId);
  };

  const rowEditHandler = (jobTitleId) => {
    handleEditJobTitleModalShow(jobTitleId);
  };

  useEffect(() => {
    if (jobTitleListFromReducer?.length) {
      setCollection(jobTitleListFromReducer);
    } else if (jobTitleListFromReducer?.length === 0) {
      setCollection([]);
    }
  }, [jobTitleListFromReducer]);

  const searchData = useRef(
    throttle((val) => {
      const query = val?.trim()?.toLowerCase();
      setCurrentPage(1);

      if (listRef?.current?.length) {
        const data = listRef.current
          .filter((item) => {
            const jobTitle = item?.job_title?.toLowerCase() || "";
            return jobTitle.indexOf(query) > -1;
          })
          .slice(0, countPerPage);

        setCollection(data);
      } else {
        console.log("No data in listRef.current");
        setCollection([]);
      }
    }, 300)
  );

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(jobTitleListFromReducer?.slice(from, to));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <th key={index}>{title}</th>
    ));
  };

  useEffect(() => {
    setCompanyLoading(true);
    dispatch(getCompanyList())
      .then((res) => {
        setCompanyLoading(false);
      })
      .catch((err) => {
        setCompanyLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (companyListFromReducer?.length) {
      setCompanyOptions(companyListFromReducer);
    }
  }, [dispatch, companyListFromReducer]);

  useEffect(() => {
    if (
      selectedCompany?.id !== undefined &&
      selectedCompany?.id !== "" &&
      companyListFromReducer?.length
    ) {
      setLoading(true);

      dispatch(getJobTitlesByCompanyId(selectedCompany?.id))
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [selectedCompany]);

  return (
    <Container fluid className="p-0">
      <Row className="searchContainer">
        <Col lg={5} md={5}>
          <div className="">
            <Select
              className=""
              options={companyOptions}
              onChange={(e) => {
                setSelectedCompany(e);
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={" "}
              disabled={companyLoading}
              value={selectedCompany}
              styles={colourStylesState}
              isClearable={false}
              label={companyLoading ? "Loading options" : "Company"}
              components={{
                Control: Control,
                // DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </Col>
        <Col lg={5} md={5} className="search">
          <InputField
            className={"did-floating-input"}
            classNameLabel={"did-floating-label"}
            type="text"
            name="lastName"
            label={"Job Title"}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Col>

        <Col
          lg="2"
          md={2}
          className="new-message-btn d-flex gap-2 justify-content-end"
        >
          <Tooltip
            title={
              selectedCompany?.id ? "Add Job Title" : "Select Company first"
            }
          >
            <Button
              className="new-message"
              onClick={selectedCompany?.id && handleAddJobTitleModalShow}
            >
              <FaUsersCog />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <div className="table-responsive">
        <table id="paginationTable">
          <thead id="paginationTableHead">
            <tr id="paginationTableRow">{headRow()}</tr>
          </thead>
          <tbody id="paginationTableBody" className="trhover">
            {collection?.length === 0 ? (
              <tr>
                <td>
                  {selectedCompany
                    ? "No Records Found"
                    : "Please Select Company"}
                </td>
              </tr>
            ) : (
              collection?.length &&
              collection?.map((jobTitle, index) => {
                return (
                  <tr key={jobTitle?._id}>
                    <td>{jobTitle?.job_title}</td>
                    <td>
                      <Tooltip
                        title={
                          jobTitle?.user_principal?.length
                            ? jobTitle?.user_principal
                                .map((userPrincipal, index) => {
                                  return userPrincipal;
                                })
                                .join(", ")
                            : "-N/A-"
                        }
                      >
                        {jobTitle?.user_principal?.length > 2
                          ? jobTitle.user_principal.slice(0, 2).join(", ") +
                            ", ..."
                          : jobTitle?.user_principal?.length
                          ? jobTitle.user_principal.join(", ")
                          : "-N/A-"}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        title={
                          jobTitle?.reports?.length
                            ? jobTitle?.reports
                                .map((report, index) => {
                                  return report;
                                })
                                .join(", ")
                            : "-N/A-"
                        }
                      >
                        {jobTitle?.reports?.length > 2
                          ? jobTitle.reports.slice(0, 2).join(", ") + ", ..."
                          : jobTitle?.reports?.length
                          ? jobTitle.reports.join(", ")
                          : "-N/A-"}
                      </Tooltip>
                    </td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "24px",
                          padding: "4px",
                        }}
                      >
                        <Tooltip title="Remove Job Title">
                          <Link
                            onClick={() => {
                              rowDeleteHandler(jobTitle?._id);
                            }}
                          >
                            <i
                              style={{ color: "#3a49a1" }}
                              className="fa fa-times"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </Tooltip>
                        <Tooltip
                          title={
                            selectedCompany?.id
                              ? "Edit Job Title"
                              : "Select Company first"
                          }
                        >
                          <Link
                            disabled={selectedCompany?.id ? false : true}
                            onClick={() => {
                              rowEditHandler(jobTitle?._id);
                            }}
                          >
                            <img
                              src="/assets/images/editIcon.svg"
                              className="img-fluid"
                              alt="icon"
                            />
                          </Link>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        showTitle={false}
        className="paginate-sec"
        pageSize={countPerPage}
        onChange={updatePage}
        current={currentPage}
        total={jobTitleListFromReducer.length}
        showLessItems={true}
      >
        {renderPaginationItems()}
      </Pagination>

      <ModalComponent
        show={showDeleteModal}
        handleClose={handleDeleteModalClose}
        keyboard={true}
        size="md"
      >
        <div className="delete-modal">
          <i className="fa fa-trash" aria-hidden="true"></i>
          <h4>Remove</h4>
          <p>Are you sure you want to Remove this Job Title ?</p>
          <div className="d-flex align-items-center gap-2 justify-content-center">
            <Button className="white-back" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
            <Button className="red-back" onClick={deleteJobTitleHandler}>
              Remove
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalWrapper
        showModal={showAddJobTitleModal}
        handleClose={handleAddJobTitleModalClose}
        title="Add Job Title"
        keyboard={true}
        size="lg"
      >
        <SuperAdminAddJobTitle
          handleAddJobTitleModalClose={handleAddJobTitleModalClose}
          company={selectedCompany}
        />
      </ModalWrapper>
      <ModalWrapper
        keyboard={true}
        showModal={showEditJobTitleModal}
        handleClose={handleEditJobTitleModalClose}
        title={"Update Job Title"}
        size="lg"
      >
        <SuperAdminEditJobTitle
          company={selectedCompany}
          jobTitleId={jobTitleId}
          handleEditJobTitleModalClose={handleEditJobTitleModalClose}
        />
      </ModalWrapper>

      <Spinner
        isLoading={
          loading || jobTitleLoading || companyLoading || reportLoading
        }
      />
    </Container>
  );
};
export default JobTitleListScreen;
