import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AiFillWindows } from "react-icons/ai";
import { loginSchema } from "../../utils/validationSchema/validation";

import { decodeToken } from "react-jwt";
import {
  osVersion,
  osName,
  mobileVendor,
  browserName,
  fullBrowserVersion,
  mobileModel,
  deviceType,
} from "react-device-detect";
import {
  REACT_APP_CRYPTOJS_SECRETKEY,
  REACT_APP_CRYPTOJS_VECTOR,
  REACT_APP_BACKEND_URL,
} from "../../configs/config";

import { Col, Container, Form, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login, userLogin } from "../../redux/slice/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../components/layout/shared/Spinner";
import { Helmet } from "react-helmet";

const defaultValues = {
  password: "",
  email: "",
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleShowpassword = () => {
    setShowPassword((prev) => !prev);
  };

  const [user, setUser] = useState(null);
  useEffect(() => {
    const handleMessage = (event) => {
      // Handle messages from the popup
      if (event.data.status === "success") {
        console.log("Response success ------->:", event.data);
        setUser(event.data); // Set the user data
        const userData = event.data.token && decodeToken(event.data.token);
        console.log("userData", userData);
        const payload = {
          user: {
            ...userData,
            role: event?.data?.role,
            company: {
              company_id: event?.data?.company_id,
            },
            job_title: event?.data?.job_title,
            user_principal: event?.data?.user_principal?.user_principal,
            email: userData?.upn,
            _id: event?.data?.id,
          },
          token: event?.data?.token,
          isOtpVerified: event?.data?.isOtpVerified,
        };
        dispatch(login(payload));
        navigate(`/${(event?.data?.role?.[0]).toLowerCase()}/dashboard`);
      }
    };

    window.addEventListener("message", handleMessage);
    // Cleanup
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const handleMicrosoftLogin = () => {
    const width = 600;
    const height = 700;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    // Open the popup window
    window.open(
      `${REACT_APP_BACKEND_URL}auth/sso/login`,
      "MicrosoftLogin",
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,status=yes`
    );
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    const encryptedStoredEmail = localStorage.getItem("email");
    const encryptedStoredPassword = localStorage.getItem("password");
    const encryptedStoredRememberMe = localStorage.getItem("rememberMe");

    if (
      encryptedStoredEmail &&
      encryptedStoredEmail !== null &&
      encryptedStoredPassword &&
      encryptedStoredPassword !== null &&
      encryptedStoredRememberMe &&
      encryptedStoredRememberMe !== null
    ) {
      try {
        // Attempt decryption
        const storedEmail = CryptoJS.AES.decrypt(
          encryptedStoredEmail,
          REACT_APP_CRYPTOJS_SECRETKEY,
          { iv: REACT_APP_CRYPTOJS_VECTOR }
        ).toString(CryptoJS.enc.Utf8);

        const storedPassword = CryptoJS.AES.decrypt(
          encryptedStoredPassword,
          REACT_APP_CRYPTOJS_SECRETKEY,
          { iv: REACT_APP_CRYPTOJS_VECTOR }
        ).toString(CryptoJS.enc.Utf8);

        // Handle the decrypted data
        setValue("email", storedEmail);
        setValue("password", storedPassword);
        setRememberMe(true);
      } catch (error) {
        // Handle decryption error (e.g., invalid key or IV)
        console.error("Decryption error:", error);
      }
    }
  }, []);

  const onSubmit = async (data) => {
    let device = `OS:${osName} + OS_VERSION:${osVersion} + MODEL:${mobileModel} +  VENDOR:${mobileVendor}`;
    let browser = `BROWSER:${browserName} + BW_VERSION:${fullBrowserVersion} + DEVICE_TYPE:${deviceType}`;
    const params = {
      email: data?.email,
      password: data?.password,
      device: device,
      browser: browser,
    };

    // Set loading to true when the login request starts
    setLoading(true);

    try {
      const userData = await dispatch(userLogin(params));

      if (
        !userData?.payload ||
        userData?.payload === undefined ||
        userData?.payload?.match === false
      ) {
        return;
      } else {
        if (userData?.payload?.isVerified === false) {
          navigate(`/verify-mobile`, {
            state: {
              email: `${userData?.payload?.email}`,
              role: `${userData?.payload?.role?.[0]}`,
              id: `${userData?.payload?._id}`,
            },
          });
        } else if (
          userData?.payload?.isVerified &&
          userData?.payload?.payload?._id !== undefined
        ) {
          navigate(
            `/${(userData?.payload?.payload?.role?.[0]).toLowerCase()}/dashboard`
          );
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("An error occurred during login");
    } finally {
      setLoading(false);
      if (rememberMe) {
        localStorage.setItem(
          "email",
          CryptoJS.AES.encrypt(data?.email, REACT_APP_CRYPTOJS_SECRETKEY, {
            REACT_APP_CRYPTOJS_VECTOR,
          }).toString()
        );
        localStorage.setItem(
          "password",
          CryptoJS.AES.encrypt(data?.password, REACT_APP_CRYPTOJS_SECRETKEY, {
            REACT_APP_CRYPTOJS_VECTOR,
          }).toString()
        );
        localStorage.setItem("rememberMe", true);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
        localStorage.removeItem("rememberMe");
      }
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        {/* Google Tag Manager (gtag.js) */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-KTZSBVM3T1"
        ></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-KTZSBVM3T1');
    `}
        </script>
      </Helmet>
      <div className="login-section">
        <Container fluid className="" style={{ paddingLeft: "0" }}>
          <Row className="align-items-center">
            <Col md={5} className="p-0">
              <div className="text-center response-image">
                <img
                  src={"/assets/images/white_logo.png"}
                  className="img-fluid"
                  alt="icon"
                />
              </div>
            </Col>
            <Col md="5" className="m-auto">
              <div className="shadow-box">
                <h3 className="d-flex align-items-center justify-content-center gap-2">
                  {" "}
                  <img
                    src={"/assets/images/login.svg"}
                    className="img-fluid"
                    alt="icon"
                    style={{ height: "35px" }}
                  />{" "}
                  Login
                </h3>
                <Row className="align-items-center">
                  <Col md={12}>
                    <div className="login-form dash-login">
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group
                          className="did-floating-label-content mb-3"
                          controlId="formBasicEmail"
                        >
                          <Controller
                            control={control}
                            name="email"
                            render={({ field }) => (
                              <input
                                className="did-floating-input"
                                placeholder=" "
                                type="text"
                                value={field?.value}
                                onChange={field?.onChange}
                              />
                            )}
                          />
                          <label className="did-floating-label ">Email*</label>
                          {errors.email && (
                            <Form.Text className="text-danger">
                              {errors.email.message}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group
                          className="did-floating-label-content mb-3"
                          controlId="password"
                        >
                          <Controller
                            control={control}
                            name="password"
                            render={({ field }) => (
                              <input
                                className="did-floating-input"
                                placeholder=" "
                                type={showPassword ? "text" : "password"}
                                value={field?.value}
                                onChange={field?.onChange}
                              />
                            )}
                          />
                          <label className="did-floating-label">
                            Password*
                          </label>
                          {showPassword ? (
                            <span
                              onClick={toggleShowpassword}
                              id="#password-field"
                              style={{
                                color: "#24a5a5",
                                float: "right",
                                marginLeft: "-25px",
                                marginTop: "8px",
                                position: "relative",
                                right: "10px",
                                zIndex: "2",
                                cursor: "pointer",
                              }}
                              className="fa fa-fw fa-eye field-icon toggle-password"
                            ></span>
                          ) : (
                            <span
                              onClick={toggleShowpassword}
                              style={{
                                color: "#24a5a5",
                                float: "right",
                                marginLeft: "-25px",
                                marginTop: "8px",
                                position: "relative",
                                right: "10px",
                                zIndex: "2",
                                cursor: "pointer",
                              }}
                              id="#password-field"
                              className="fa fa-fw fa-eye-slash field-icon toggle-password"
                            ></span>
                          )}
                          {errors.password && (
                            <Form.Text className="text-danger">
                              {errors.password.message}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group controlId="rememberMe">
                          <Form.Check
                            type="checkbox"
                            label="Remember Me"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                          />
                          {errors.terms && (
                            <Form.Text className="text-danger">
                              {errors.terms.message}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <div>
                          <button
                            className={`login-btn w-100 m-0 ${
                              loading ? "disabled" : ""
                            }`}
                            type="submit" // Change to type="submit" to trigger form submission
                            disabled={loading} // Disable the button while loading
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                      <div>
                        <button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={`login-btn w-100 mt-2 ${
                            loading ? "disabled" : ""
                          }`}
                          onClick={handleMicrosoftLogin}
                          disabled={loading} // Disable the button while loading
                        >
                          <span
                            style={{ color: "#fff", marginRight: "2px" }}
                            className="mr-2"
                          >
                            Login with Microsoft
                          </span>{" "}
                          <AiFillWindows
                            style={{ marginLeft: "2px" }}
                            size={"16px"}
                          />
                        </button>
                      </div>
                      <div className="d-flex flex-row justify-content-between mt-3">
                        <div className="login-redirect-link">
                          <a
                            target="_blank"
                            href="https://snf-infocus.com"
                            rel="noreferrer"
                          >
                            SNF InFocus
                          </a>
                          {" | "}
                          <a
                            href="https://snf-infocus.com/privacy-policy"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </div>
                        <Link to="/forgot-password">Forgot password?</Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Spinner isLoading={loading} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
