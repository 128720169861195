import React, { useEffect } from "react";
import DashHeader from "./DashHeader";
import SuperAdminSidebar from "./SuperAdminSidebar";
import { Helmet } from "react-helmet";
import Footer from "./Footer";

function SuperAdminLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>Client Access - SNF InFocus</title>
      </Helmet>
      <div className="dashboard-layout">
        <div className="inner-dash-bpx p-0 m-0">
          <DashHeader />
          <SuperAdminSidebar>{props.children}</SuperAdminSidebar>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
}

export default React.memo(SuperAdminLayout);
