import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { toggleLoading } from "../../redux/slice/loadingSlice";
import { getReportByGroupId } from "../../redux/slice/reportSlice";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { SiPowerbi } from "react-icons/si";

import { BiSolidReport } from "react-icons/bi";
import UserSidebarMenu from "./UserSidebarMenu";

const UserSidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "auto",
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  const [route, setRoute] = useState([
    {
      path: "/user/report/",
      name: "Dashboard",
      icon: (
        <i
          className="fa fa-file"
          aria-hidden="true"
          width="20px"
          height="20px"
        ></i>
      ),
    },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reportsListFromReducer = useSelector(({ report }) => report.reportList);
  const loading = useSelector(({ report }) => report.loading);

  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);

  useEffect(() => {
    if (loggedInUser) {
      dispatch(toggleLoading(true));
      dispatch(getReportByGroupId(loggedInUser?.company?.company_id));
      dispatch(toggleLoading(false));
    }
  }, [dispatch]);

  useEffect(() => {
    if (reportsListFromReducer?.length) {
      const updatedData = reportsListFromReducer?.map((report, index) => {
        return {
          path: `/user/reports/${report?.id}`,
          name: report?.name,
          // icon: <BiSolidReport />,
          report: report,
        };
      });

      setRoute((old) => {
        return old.map((el) => {
          return {
            path: updatedData?.[0]?.id,
            name: "Reports",
            icon: <SiPowerbi />,
            subRoutes: updatedData,
          };
        });
      });
    }
  }, [reportsListFromReducer]);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="dashboard-main-sec">
      <div
        style={{
          width: isOpen ? "270px" : "76px",
        }}
        className="sidebar"
      >
        <div className="top_section">
          <div
            style={{ marginLeft: isOpen ? "50px" : "0px", cursor: "pointer" }}
            className="bars"
          >
            {isOpen ? (
              <BsFillArrowLeftCircleFill onClick={toggle} />
            ) : (
              <BsFillArrowRightCircleFill onClick={toggle} />
            )}
          </div>
        </div>
        {route.map((route, index) => {
          if (route?.subRoutes) {
            return (
              <UserSidebarMenu
                showAnimation={showAnimation}
                isOpen={isOpen}
                route={route}
                key={route.name}
                setIsOpen={setIsOpen}
              />
            );
          }
          return (
            <NavLink
              to={route.path}
              key={index}
              className="link"
              activeclassname="active"
              state={{ report: route.report }}
            >
              <div className="icon">{route.icon}</div>
              <div
                style={{
                  display: isOpen ? "block" : "none",
                  fontFamily: "Segoe UI",
                }}
                className="link_text"
              >
                {route.name}
              </div>
            </NavLink>
          );
        })}
      </div>
      <main>{children}</main>
    </div>
  );
};

export default UserSidebar;
