import React from "react";

const PaginationEllipsis = () => {
  return (
    <li className="rc-pagination-item rc-pagination-jump-prev">
      <a className="rc-pagination-item-link">...</a>
    </li>
  );
};

export default PaginationEllipsis;
