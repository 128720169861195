import React, { useEffect, useState } from "react";
import { Navbar, Container, Col, Row, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { userLogout } from "../../redux/slice/authSlice";
import Select, { components } from "react-select";
import { BsPerson } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";
import { firstCapitalLetter } from "../../utils/commonFunction";
import { getCompanyList } from "../../redux/slice/companySlice";
import { setSelectedCompanyData } from "../../redux/slice/companySlice";
import { getReportByGroupId } from "../../redux/slice/reportSlice";
import { RiLockPasswordLine } from "react-icons/ri";

export const Control = (props) => {
  return (
    <>
      <label
        className={
          props.hasValue || props.isFocused
            ? "did-floating-label did-floating-label-top"
            : "did-floating-label did-floating-label"
        }
        style={{ zIndex: 1 }}
      >
        {props?.selectProps?.label}
      </label>
      <components.Control {...props} />
    </>
  );
};

const colourStylesState = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    boxShadow: "none",
    padding: "2px 10px",
    border: "none",
    outline: "none",
    background: "transparent",
    color: "#fff",
    backgroundImage: "transparent!important",
    "&:focus": {
      backgroundImage: "transparent",
      outline: "none",
      border: "none",
      cursor: "pointer",
      color: "#fff",
    },
    "&:active": {
      backgroundImage: "transparent!important",
      outline: "none",
      border: "none",
      cursor: "pointer",
      color: "#fff",
    },
    "&:hover": {
      backgroundImage: "transparent!important",
      outline: "none",
      border: "none",
      cursor: "pointer",
      color: "#fff",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#ffffff",
      fontSize: "16px",
      fontFamily: "Segoe UI",
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 12,
    color: "#fff",
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 200, // set the z-index to a high value
    color: "#fff",
  }),
  menuList: (base) => ({
    ...base,
    height: "auto",
    color: "#fff",
    backgroundImage: "transparent!important",
    "::-webkit-scrollbar": {
      width: "9px",
      backgroundImage: "transparent!important",
    },
    "::-webkit-scrollbar-track": {
      backgroundImage: "transparent!important",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundImage: "transparent!important",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundImage: "transparent!important",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#374151",
    backgroundColor: "#fff !important",
    "&:hover": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)",
      color: "#fff",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    "&:focus": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)",
      backgroundColor: "#fff",
      color: "#fff",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    "&:active": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
      backgroundColor: "#fff",
      color: "#fff",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#fff",
    svg: {
      fill: "#fff",
    },
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff",
    cursor: "text",
  }),
};
const reportsStylesState = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    boxShadow: "none",
    padding: "2px 10px",
    minWidth: "10rem",
    border: "none",
    outline: "none",
    background: "transparent",
    color: "#fff",
    backgroundImage: "transparent!important",
    "&:focus-within": {
      backgroundImage: "transparent",
      // backgroundColor: "#fff",
      outline: "none",
      border: "none",
      cursor: "pointer",
      color: "#fff",
    },
    "&:focus": {
      backgroundImage: "transparent",
      outline: "none",
      border: "none",
      cursor: "pointer",
      color: "#fff",
    },

    "&:active": {
      backgroundImage: "transparent!important",
      outline: "none",
      border: "none",
      cursor: "pointer",
      color: "#fff",
    },
    "&:hover": {
      backgroundImage: "transparent!important",
      outline: "none",
      border: "none",
      cursor: "pointer",
      color: "#fff",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fff !important",
    fontSize: "16px !important",
    fontFamily: "Segoe UI",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#ffffff",
      fontSize: "16px",
      fontFamily: "Segoe UI",
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 12,
    color: "#fff",
  }),
  menu: (base) => ({
    ...base,
    fontSize: 12,
    zIndex: 200, // set the z-index to a high value
    color: "#fff",
    minWidth: "18rem",
  }),
  menuList: (base) => ({
    ...base,
    height: "auto",
    minWidth: "15rem",
    color: "#fff",
    backgroundImage: "transparent!important",
    "::-webkit-scrollbar": {
      width: "9px",
      backgroundImage: "transparent!important",
    },
    "::-webkit-scrollbar-track": {
      background: "#24a5a5 !important",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#3a4981 !important",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: " !important",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    minWidth: "15rem",
    color: "#374151",
    backgroundColor: "#fff !important",
    "&:hover": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)",
      color: "#fff",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    "&:focus": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)",
      backgroundColor: "#fff",
      color: "#fff",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    "&:active": {
      backgroundImage: "linear-gradient(-90deg, #24a5a5, #3a4981)!important",
      backgroundColor: "#fff",
      color: "#fff",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#fff",
    svg: {
      fill: "#fff",
    },
  }),
  input: (provided) => ({
    ...provided,
    color: "#fff",
    cursor: "text",
  }),
};

const DashHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUser = useSelector(({ auth }) => auth.loggedInUser);

  const selectedCompanyData = useSelector(
    ({ company }) => company.selectedCompanyData
  );
  const companyListFromReducer = useSelector(
    ({ company }) => company.companyList
  );
  const reportList = useSelector(({ report }) => report.reportList);

  const logoutHandler = () => {
    dispatch(userLogout(loggedInUser?._id)).then((res) => {
      navigate("/", { replace: true });
    });
  };

  const [companyLoading, setCompanyLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);

  useEffect(() => {
    setCompanyLoading(true);
    dispatch(getCompanyList())
      .then((res) => {
        setCompanyLoading(false);
      })
      .catch((err) => {
        setCompanyLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (selectedCompany?.id !== undefined && selectedCompany?.id !== "") {
      setCompanyLoading(true);
      dispatch(
        getReportByGroupId(
          selectedCompanyData ? selectedCompanyData?.id : selectedCompany?.id
        )
      );
    }
  }, [selectedCompany]);

  // Sort reportList alphabetically by name
  const sortedReportList = reportList
    ?.slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <header className="main-header login-header-home">
        <Navbar expand="lg" sticky="top">
          <Container fluid>
            <Row className="w-100 m-auto">
              <Col>
                <div className="d-flex align-items-center">
                  <Navbar.Brand className="d-block p-0">
                    <Link to="/superadmin/dashboard">
                      <img
                        src={"/assets/images/white_logo.png"}
                        className="img-fluid"
                        alt="logo"
                      />
                    </Link>
                  </Navbar.Brand>
                  <div className="d-flex align-items-center justify-content-end  w-100">
                    <div>
                      <Select
                        className="topbar-dropdown-company"
                        options={companyListFromReducer}
                        onChange={(e) => {
                          dispatch(setSelectedCompanyData(e));
                          setSelectedCompany(e);
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder={"Select Company"}
                        disabled={companyLoading}
                        value={
                          selectedCompanyData
                            ? selectedCompanyData
                            : selectedCompany
                        }
                        styles={colourStylesState}
                        components={{
                          Control: Control,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                    <Select
                      className="topbar-dropdown-report"
                      options={sortedReportList}
                      onChange={(selectedReport) => {
                        navigate(`/superadmin/reports/${selectedReport.id}`, {
                          state: {
                            report: selectedReport,
                            selectedCompanyData: selectedCompanyData,
                          },
                        });
                        setSelectedReport(selectedReport);
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder={"Select Report"}
                      isDisabled={selectedCompanyData?.id === undefined}
                      styles={reportsStylesState}
                      components={{
                        Control: Control,
                        IndicatorSeparator: () => null,
                      }}
                    />

                    <Dropdown className="dropdown-notify">
                      <Dropdown.Toggle id="dropdown-basic">
                        <div>
                          <div
                            style={{
                              display: "inline-block",
                              borderRadius: "50%",
                              border: "2px solid #3a4981",
                              height: "30px",
                              width: "30px",
                              marginRight: "5px",
                              backgroundColor: "#3a4981",
                              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                              backdropFilter: "blur(2px)",
                            }}
                          >
                            {loggedInUser?.firstName
                              ? loggedInUser?.firstName
                                  ?.charAt(0)
                                  .toUpperCase() +
                                loggedInUser?.lastName?.charAt(0).toUpperCase()
                              : loggedInUser?.given_name
                                  ?.charAt(0)
                                  .toUpperCase() +
                                loggedInUser?.family_name
                                  ?.charAt(0)
                                  .toUpperCase()}
                          </div>
                          {firstCapitalLetter(
                            loggedInUser?.firstName
                              ? loggedInUser?.firstName
                              : loggedInUser?.given_name
                          ) +
                            " " +
                            firstCapitalLetter(
                              loggedInUser?.lastName
                                ? loggedInUser?.lastName
                                : loggedInUser?.family_name
                            )}
                        </div>
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item className="d-block p-0">
                          <Link to="/superadmin/profile">
                            <BsPerson />
                            My Profile
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item className="d-block p-0">
                          <Link
                            to={`/superadmin/change-password/${loggedInUser?._id}`}
                          >
                            <RiLockPasswordLine />
                            Change Password
                          </Link>
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={logoutHandler}
                          className="d-block p-0"
                        >
                          <Link to="/login">
                            <MdOutlineLogout />
                            Logout
                          </Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default React.memo(DashHeader);
